/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SeelenbildZelle
 */
export interface SeelenbildZelle {
    /**
     * 
     * @type {string}
     * @memberof SeelenbildZelle
     */
    labelText?: string;
    /**
     * 
     * @type {string}
     * @memberof SeelenbildZelle
     */
    labelAlignment?: SeelenbildZelleLabelAlignmentEnum;
    /**
     * 
     * @type {number}
     * @memberof SeelenbildZelle
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof SeelenbildZelle
     */
    dynamicNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof SeelenbildZelle
     */
    numberOccurrence?: number;
    /**
     * 
     * @type {number}
     * @memberof SeelenbildZelle
     */
    superscriptNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof SeelenbildZelle
     */
    superscriptNumberOccurrence?: number;
    /**
     * 
     * @type {string}
     * @memberof SeelenbildZelle
     */
    superscriptNumberColor?: SeelenbildZelleSuperscriptNumberColorEnum;
    /**
     * 
     * @type {number}
     * @memberof SeelenbildZelle
     */
    row?: number;
    /**
     * 
     * @type {number}
     * @memberof SeelenbildZelle
     */
    column?: number;
}


/**
 * @export
 */
export const SeelenbildZelleLabelAlignmentEnum = {
    Left: 'LEFT',
    Center: 'CENTER'
} as const;
export type SeelenbildZelleLabelAlignmentEnum = typeof SeelenbildZelleLabelAlignmentEnum[keyof typeof SeelenbildZelleLabelAlignmentEnum];

/**
 * @export
 */
export const SeelenbildZelleSuperscriptNumberColorEnum = {
    Special: 'SUPERSCRIPT_SPECIAL',
    Normal: 'SUPERSCRIPT_NORMAL'
} as const;
export type SeelenbildZelleSuperscriptNumberColorEnum = typeof SeelenbildZelleSuperscriptNumberColorEnum[keyof typeof SeelenbildZelleSuperscriptNumberColorEnum];


/**
 * Check if a given object implements the SeelenbildZelle interface.
 */
export function instanceOfSeelenbildZelle(value: object): value is SeelenbildZelle {
    return true;
}

export function SeelenbildZelleFromJSON(json: any): SeelenbildZelle {
    return SeelenbildZelleFromJSONTyped(json, false);
}

export function SeelenbildZelleFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeelenbildZelle {
    if (json == null) {
        return json;
    }
    return {
        
        'labelText': json['labelText'] == null ? undefined : json['labelText'],
        'labelAlignment': json['labelAlignment'] == null ? undefined : json['labelAlignment'],
        'number': json['number'] == null ? undefined : json['number'],
        'dynamicNumber': json['dynamicNumber'] == null ? undefined : json['dynamicNumber'],
        'numberOccurrence': json['numberOccurrence'] == null ? undefined : json['numberOccurrence'],
        'superscriptNumber': json['superscriptNumber'] == null ? undefined : json['superscriptNumber'],
        'superscriptNumberOccurrence': json['superscriptNumberOccurrence'] == null ? undefined : json['superscriptNumberOccurrence'],
        'superscriptNumberColor': json['superscriptNumberColor'] == null ? undefined : json['superscriptNumberColor'],
        'row': json['row'] == null ? undefined : json['row'],
        'column': json['column'] == null ? undefined : json['column'],
    };
}

export function SeelenbildZelleToJSON(value?: SeelenbildZelle | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'labelText': value['labelText'],
        'labelAlignment': value['labelAlignment'],
        'number': value['number'],
        'dynamicNumber': value['dynamicNumber'],
        'numberOccurrence': value['numberOccurrence'],
        'superscriptNumber': value['superscriptNumber'],
        'superscriptNumberOccurrence': value['superscriptNumberOccurrence'],
        'superscriptNumberColor': value['superscriptNumberColor'],
        'row': value['row'],
        'column': value['column'],
    };
}

