import { Button, Card, Spacer, Text, Container, Input, Loading } from "@nextui-org/react";
import { DEFAULT_TOASTER_OPTIONS } from "@utils/constants";
import { useState } from "react";
import { toast } from "react-toastify";
import { AuthControllerApi } from "../../client";
import { useHookstate } from "@hookstate/core";
import { apiConfig } from "@api/configuration";
import { loggedInUserState } from "@states/LoggedInUserState";

export const Login = () => {
  const api = new AuthControllerApi(apiConfig);
  const loggedInUser = useHookstate(loggedInUserState);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  //const [isValidEmail, setIsValidEmail] = useState(true);

  const validateEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateUsername = (username: string) => {
    if (!username.trim()) {
      return false;
    }
    if (!/^[a-zA-Z0-9]+$/.test(username)) {
      return false;
    }
    return true;
  };

  const handleUsernameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setUsername(value);
    //setIsValidEmail(validateEmail(value)); // Validierungs-Funktion aufrufen
  };

  const handlePasswordInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPassword(value);
  };

  const handleSubmit = () => {
    setButtonLoading(true);

    // wait for a little bit and then run the following code
    setTimeout(() => {
      // Führt den Anmeldeprozess aus
      if (validateUsername(username)) {
        api.authenticateUser({
          loginRequest: {
            username: username,
            password: password
          }
        }).then(res => {
          // Update loggedInUserState with the new values
          loggedInUserState.set({
            id: res.id || 0,
            username: res.username || "",
            email: res.email || "",
            roles: res.roles || []
          });
        }).catch(error => {
          toast.error("Bitte überprüfen Sie Ihre Anmeldedaten und versuchen es erneut", DEFAULT_TOASTER_OPTIONS);
        });

      } else {
        toast.error("Ungültiger Benutzername", DEFAULT_TOASTER_OPTIONS);
      }

      setButtonLoading(false); // Versteckt den Ladebalken nach dem Anmeldeprozess
    }, 1000);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

    return (
    <Container css={{ maxWidth: "30%", '@media screen and (max-width: 768px)': { maxWidth: "90%" } }}>
            <Spacer />
            <Spacer />
            {/* Heading */}
            <Text
                h1
                size={48}
                css={{ textGradient: "45deg, $blue600 -20%, $pink600 50%", marginBottom: 0, textAlign: "center"}}
                weight="bold">
                Dux Vitae
            </Text>

            <Spacer />
            <Card>
                <Card.Body>
                    <Input
                        onInput={handleUsernameInputChange}
                        css={{display: "flex"}}
                        placeholder="Benutzername"
                        onKeyDown={handleKeyPress}
                    />
                  <Spacer/>
                  <Input.Password
                    placeholder="Passwort"
                    onInput={handlePasswordInputChange}
                    onKeyDown={handleKeyPress}
                  />
                    <Spacer/>
                        <Button
                            onPress={handleSubmit}
                            disabled={false}
                            style={{width: "100%"}}
                        >
                          {buttonLoading ? <Loading type="points" color="currentColor" size="sm" /> : 'Anmelden'}
                        </Button>
                </Card.Body>
            </Card>
        </Container>
    );
}
