/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LunareZahlen
 */
export interface LunareZahlen {
    /**
     * 
     * @type {number}
     * @memberof LunareZahlen
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LunareZahlen
     */
    kreis: string;
    /**
     * 
     * @type {string}
     * @memberof LunareZahlen
     */
    weg: string;
}

/**
 * Check if a given object implements the LunareZahlen interface.
 */
export function instanceOfLunareZahlen(value: object): value is LunareZahlen {
    if (!('kreis' in value) || value['kreis'] === undefined) return false;
    if (!('weg' in value) || value['weg'] === undefined) return false;
    return true;
}

export function LunareZahlenFromJSON(json: any): LunareZahlen {
    return LunareZahlenFromJSONTyped(json, false);
}

export function LunareZahlenFromJSONTyped(json: any, ignoreDiscriminator: boolean): LunareZahlen {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'kreis': json['kreis'],
        'weg': json['weg'],
    };
}

export function LunareZahlenToJSON(value?: LunareZahlen | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'kreis': value['kreis'],
        'weg': value['weg'],
    };
}

