import { Card, Container, Grid, Input, Spacer, Text} from "@nextui-org/react";
import { useState } from "react";
import { SolareZahlenControllerApi } from "../../client";
import { apiConfig } from "@api/configuration";

export const Info = () => {
    const [quersumme, setQuersumme] = useState<number>(0);
    const [solareZahl, setSolareZahl] = useState<string>("");
    const [solareZahlText, setSolareZahlText] = useState<string>("Bitte wählen Sie zuerst eine Solare Zahl aus");
    const solareZahlenApi = new SolareZahlenControllerApi(apiConfig);
  const [inputValue, setInputValue] = useState<string>("");

    const berechneQuersumme = (num: number) => {
        let sum = 0;
        while (num) {
            sum += num % 10;
            num = Math.floor(num / 10);
        }
        return sum;
    }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    const value = input.value.replace(/[^0-9]/g, '');
    setInputValue(value);
    if (value.length > 0) {
      setQuersumme(berechneQuersumme(parseInt(value)));
    } else {
      setQuersumme(0);
    }
  };
    
    const pruefeSolareZahl = (value: string) => {
        if (value.match(/[^0-9]/g)) {
            return "0";
        } else if (Number(value) < 0) {
            return "0";
        } else if (Number(value) > 109) {
            return "109";
        } else {
            return value;
        }
    }

    return (
        <Container css={{maxWidth: "1400px"}}>
            <Spacer />
            <Spacer />

            {/* Heading */}
            <Text
                h1
                size={48}
                css={{ textGradient: "45deg, $blue600 -20%, $pink600 50%", marginBottom: 0}}
                weight="bold">
                Info
            </Text>

            <Spacer y={1}></Spacer>

            <Grid.Container gap={2}>
                <Grid xs={12} md={6}>
                    <Card>
                        <Card.Body>
                            <Text h4>Welche Solare Zahl soll geprüft werden?</Text>
                            <Input
                                label="Number"
                                type="number"
                                
                                onInput={({target}) => {
                                    const input = target as HTMLInputElement;
                                    input.value = pruefeSolareZahl(input.value);
                                    //setSolareZahl(Number(input.value));
                                    const response = solareZahlenApi.getSolareZahlenById({id: Number(input.value) + 1})
                                    response.then(response => {
                                      setSolareZahl(response.titel + "");
                                      setSolareZahlText(response.allgemein + "");
                                      console.log('Solare Zahlen response:', response);
                                    })
                                      .catch(error => {
                                        console.error('Solare Zahlen error:', error);
                                      });

                                }}
                            />
                            <Spacer />
                          {/*<Button>Prüfen</Button>*/}
                        </Card.Body>
                    </Card>
                </Grid>
                <Grid xs={12} md={6}>
                    <Card>
                        <Card.Body>
                            <Text h4>Welche Quersumme soll berechnet werden?</Text>
                            <Input
                                label="Number"
                                type="number"
                                value={inputValue}
                                onInput={handleInputChange}
                            />
                            <Spacer />
                            <Text h6>Berechnete Quersumme: {quersumme}</Text>
                        </Card.Body>
                    </Card>
                </Grid>
              <Grid xs={12} md={12}>
                <Card>
                  <Card.Body>
                    <Text h4>Solare Zahl {solareZahl}</Text>
                    <Text>{solareZahlText}</Text>
                  </Card.Body>
                </Card>
              </Grid>
            </Grid.Container>

          <Spacer />

        </Container>

    );
}
