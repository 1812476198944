/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LegendEntryColor } from './LegendEntryColor';
import {
    LegendEntryColorFromJSON,
    LegendEntryColorFromJSONTyped,
    LegendEntryColorToJSON,
} from './LegendEntryColor';

/**
 * 
 * @export
 * @interface Number
 */
export interface Number {
    /**
     * 
     * @type {number}
     * @memberof Number
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof Number
     */
    row?: number;
    /**
     * 
     * @type {number}
     * @memberof Number
     */
    column?: number;
    /**
     * 
     * @type {string}
     * @memberof Number
     */
    childDirection?: NumberChildDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof Number
     */
    type?: NumberTypeEnum;
    /**
     * 
     * @type {Array<Number>}
     * @memberof Number
     */
    childList?: Array<Number>;
    /**
     * 
     * @type {LegendEntryColor}
     * @memberof Number
     */
    color?: LegendEntryColor;
    /**
     * 
     * @type {number}
     * @memberof Number
     */
    childCount?: number;
    /**
     * 
     * @type {string}
     * @memberof Number
     */
    font?: string;
    /**
     * 
     * @type {Number}
     * @memberof Number
     */
    parent?: Number;
}


/**
 * @export
 */
export const NumberChildDirectionEnum = {
    North: 'NORTH',
    South: 'SOUTH',
    East: 'EAST',
    West: 'WEST'
} as const;
export type NumberChildDirectionEnum = typeof NumberChildDirectionEnum[keyof typeof NumberChildDirectionEnum];

/**
 * @export
 */
export const NumberTypeEnum = {
    StaticTalent: 'STATIC_TALENT',
    StaticLern: 'STATIC_LERN',
    Symptom: 'SYMPTOM',
    DynamicNormal: 'DYNAMIC_NORMAL',
    DynamicWithSymptom: 'DYNAMIC_WITH_SYMPTOM',
    StaticSymptom: 'STATIC_SYMPTOM'
} as const;
export type NumberTypeEnum = typeof NumberTypeEnum[keyof typeof NumberTypeEnum];


/**
 * Check if a given object implements the Number interface.
 */
export function instanceOfNumber(value: object): value is Number {
    return true;
}

export function NumberFromJSON(json: any): Number {
    return NumberFromJSONTyped(json, false);
}

export function NumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): Number {
    if (json == null) {
        return json;
    }
    return {
        
        'number': json['number'] == null ? undefined : json['number'],
        'row': json['row'] == null ? undefined : json['row'],
        'column': json['column'] == null ? undefined : json['column'],
        'childDirection': json['childDirection'] == null ? undefined : json['childDirection'],
        'type': json['type'] == null ? undefined : json['type'],
        'childList': json['childList'] == null ? undefined : ((json['childList'] as Array<any>).map(NumberFromJSON)),
        'color': json['color'] == null ? undefined : LegendEntryColorFromJSON(json['color']),
        'childCount': json['childCount'] == null ? undefined : json['childCount'],
        'font': json['font'] == null ? undefined : json['font'],
        'parent': json['parent'] == null ? undefined : NumberFromJSON(json['parent']),
    };
}

export function NumberToJSON(value?: Number | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'number': value['number'],
        'row': value['row'],
        'column': value['column'],
        'childDirection': value['childDirection'],
        'type': value['type'],
        'childList': value['childList'] == null ? undefined : ((value['childList'] as Array<any>).map(NumberToJSON)),
        'color': LegendEntryColorToJSON(value['color']),
        'childCount': value['childCount'],
        'font': value['font'],
        'parent': NumberToJSON(value['parent']),
    };
}

