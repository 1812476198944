/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SolareZahl } from './SolareZahl';
import {
    SolareZahlFromJSON,
    SolareZahlFromJSONTyped,
    SolareZahlToJSON,
} from './SolareZahl';
import type { SeelenbildZelle } from './SeelenbildZelle';
import {
    SeelenbildZelleFromJSON,
    SeelenbildZelleFromJSONTyped,
    SeelenbildZelleToJSON,
} from './SeelenbildZelle';

/**
 * 
 * @export
 * @interface SeelenbildDTO
 */
export interface SeelenbildDTO {
    /**
     * 
     * @type {Array<SeelenbildZelle>}
     * @memberof SeelenbildDTO
     */
    cellContent?: Array<SeelenbildZelle>;
    /**
     * 
     * @type {number}
     * @memberof SeelenbildDTO
     */
    emotionalerSpiegel?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SeelenbildDTO
     */
    geistigeHilfe?: Array<number>;
    /**
     * 
     * @type {Array<SolareZahl>}
     * @memberof SeelenbildDTO
     */
    solareZahlen?: Array<SolareZahl>;
    /**
     * 
     * @type {number}
     * @memberof SeelenbildDTO
     */
    wandlungsZahl?: number;
}

/**
 * Check if a given object implements the SeelenbildDTO interface.
 */
export function instanceOfSeelenbildDTO(value: object): value is SeelenbildDTO {
    return true;
}

export function SeelenbildDTOFromJSON(json: any): SeelenbildDTO {
    return SeelenbildDTOFromJSONTyped(json, false);
}

export function SeelenbildDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeelenbildDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'cellContent': json['cellContent'] == null ? undefined : ((json['cellContent'] as Array<any>).map(SeelenbildZelleFromJSON)),
        'emotionalerSpiegel': json['emotionalerSpiegel'] == null ? undefined : json['emotionalerSpiegel'],
        'geistigeHilfe': json['geistigeHilfe'] == null ? undefined : json['geistigeHilfe'],
        'solareZahlen': json['solareZahlen'] == null ? undefined : ((json['solareZahlen'] as Array<any>).map(SolareZahlFromJSON)),
        'wandlungsZahl': json['wandlungsZahl'] == null ? undefined : json['wandlungsZahl'],
    };
}

export function SeelenbildDTOToJSON(value?: SeelenbildDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cellContent': value['cellContent'] == null ? undefined : ((value['cellContent'] as Array<any>).map(SeelenbildZelleToJSON)),
        'emotionalerSpiegel': value['emotionalerSpiegel'],
        'geistigeHilfe': value['geistigeHilfe'],
        'solareZahlen': value['solareZahlen'] == null ? undefined : ((value['solareZahlen'] as Array<any>).map(SolareZahlToJSON)),
        'wandlungsZahl': value['wandlungsZahl'],
    };
}

