/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Symptome,
} from '../models/index';
import {
    SymptomeFromJSON,
    SymptomeToJSON,
} from '../models/index';

export interface CreateSymptomeRequest {
    symptome: Symptome;
}

export interface DeleteSymptomeRequest {
    id: number;
}

export interface GetSymptomeByIdRequest {
    id: number;
}

export interface UpdateSymptomeRequest {
    id: number;
    symptome: Symptome;
}

/**
 * 
 */
export class SymptomeControllerApi extends runtime.BaseAPI {

    /**
     */
    async createSymptomeRaw(requestParameters: CreateSymptomeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Symptome>> {
        if (requestParameters['symptome'] == null) {
            throw new runtime.RequiredError(
                'symptome',
                'Required parameter "symptome" was null or undefined when calling createSymptome().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/symptome`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SymptomeToJSON(requestParameters['symptome']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SymptomeFromJSON(jsonValue));
    }

    /**
     */
    async createSymptome(requestParameters: CreateSymptomeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Symptome> {
        const response = await this.createSymptomeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteSymptomeRaw(requestParameters: DeleteSymptomeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteSymptome().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/symptome/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteSymptome(requestParameters: DeleteSymptomeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSymptomeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllSymptomeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Symptome>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/symptome`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SymptomeFromJSON));
    }

    /**
     */
    async getAllSymptome(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Symptome>> {
        const response = await this.getAllSymptomeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getSymptomeByIdRaw(requestParameters: GetSymptomeByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Symptome>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getSymptomeById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/symptome/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SymptomeFromJSON(jsonValue));
    }

    /**
     */
    async getSymptomeById(requestParameters: GetSymptomeByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Symptome> {
        const response = await this.getSymptomeByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateSymptomeRaw(requestParameters: UpdateSymptomeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Symptome>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateSymptome().'
            );
        }

        if (requestParameters['symptome'] == null) {
            throw new runtime.RequiredError(
                'symptome',
                'Required parameter "symptome" was null or undefined when calling updateSymptome().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/symptome/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SymptomeToJSON(requestParameters['symptome']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SymptomeFromJSON(jsonValue));
    }

    /**
     */
    async updateSymptome(requestParameters: UpdateSymptomeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Symptome> {
        const response = await this.updateSymptomeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
