/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Number } from './Number';
import {
    NumberFromJSON,
    NumberFromJSONTyped,
    NumberToJSON,
} from './Number';
import type { SolareZahl } from './SolareZahl';
import {
    SolareZahlFromJSON,
    SolareZahlFromJSONTyped,
    SolareZahlToJSON,
} from './SolareZahl';
import type { ConnectionLine } from './ConnectionLine';
import {
    ConnectionLineFromJSON,
    ConnectionLineFromJSONTyped,
    ConnectionLineToJSON,
} from './ConnectionLine';
import type { Symptom } from './Symptom';
import {
    SymptomFromJSON,
    SymptomFromJSONTyped,
    SymptomToJSON,
} from './Symptom';

/**
 * 
 * @export
 * @interface EnergiebildDTO
 */
export interface EnergiebildDTO {
    /**
     * 
     * @type {Array<number>}
     * @memberof EnergiebildDTO
     */
    lernaufgaben?: Array<number>;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof EnergiebildDTO
     */
    polareEntsprechung?: Array<Array<number>>;
    /**
     * 
     * @type {Array<SolareZahl>}
     * @memberof EnergiebildDTO
     */
    solareZahlen?: Array<SolareZahl>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof EnergiebildDTO
     */
    nichtTalent?: Array<boolean>;
    /**
     * 
     * @type {Array<Symptom>}
     * @memberof EnergiebildDTO
     */
    symptome?: Array<Symptom>;
    /**
     * 
     * @type {Array<number>}
     * @memberof EnergiebildDTO
     */
    talente?: Array<number>;
    /**
     * 
     * @type {Array<Number>}
     * @memberof EnergiebildDTO
     */
    staticCircle?: Array<Number>;
    /**
     * 
     * @type {Array<Number>}
     * @memberof EnergiebildDTO
     */
    dynamicCircle?: Array<Number>;
    /**
     * 
     * @type {Array<ConnectionLine>}
     * @memberof EnergiebildDTO
     */
    connectionLines?: Array<ConnectionLine>;
    /**
     * 
     * @type {string}
     * @memberof EnergiebildDTO
     */
    canvas?: string;
}

/**
 * Check if a given object implements the EnergiebildDTO interface.
 */
export function instanceOfEnergiebildDTO(value: object): value is EnergiebildDTO {
    return true;
}

export function EnergiebildDTOFromJSON(json: any): EnergiebildDTO {
    return EnergiebildDTOFromJSONTyped(json, false);
}

export function EnergiebildDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergiebildDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'lernaufgaben': json['lernaufgaben'] == null ? undefined : json['lernaufgaben'],
        'polareEntsprechung': json['polareEntsprechung'] == null ? undefined : json['polareEntsprechung'],
        'solareZahlen': json['solareZahlen'] == null ? undefined : ((json['solareZahlen'] as Array<any>).map(SolareZahlFromJSON)),
        'nichtTalent': json['nichtTalent'] == null ? undefined : json['nichtTalent'],
        'symptome': json['symptome'] == null ? undefined : ((json['symptome'] as Array<any>).map(SymptomFromJSON)),
        'talente': json['talente'] == null ? undefined : json['talente'],
        'staticCircle': json['staticCircle'] == null ? undefined : ((json['staticCircle'] as Array<any>).map(NumberFromJSON)),
        'dynamicCircle': json['dynamicCircle'] == null ? undefined : ((json['dynamicCircle'] as Array<any>).map(NumberFromJSON)),
        'connectionLines': json['connectionLines'] == null ? undefined : ((json['connectionLines'] as Array<any>).map(ConnectionLineFromJSON)),
        'canvas': json['canvas'] == null ? undefined : json['canvas'],
    };
}

export function EnergiebildDTOToJSON(value?: EnergiebildDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'lernaufgaben': value['lernaufgaben'],
        'polareEntsprechung': value['polareEntsprechung'],
        'solareZahlen': value['solareZahlen'] == null ? undefined : ((value['solareZahlen'] as Array<any>).map(SolareZahlToJSON)),
        'nichtTalent': value['nichtTalent'],
        'symptome': value['symptome'] == null ? undefined : ((value['symptome'] as Array<any>).map(SymptomToJSON)),
        'talente': value['talente'],
        'staticCircle': value['staticCircle'] == null ? undefined : ((value['staticCircle'] as Array<any>).map(NumberToJSON)),
        'dynamicCircle': value['dynamicCircle'] == null ? undefined : ((value['dynamicCircle'] as Array<any>).map(NumberToJSON)),
        'connectionLines': value['connectionLines'] == null ? undefined : ((value['connectionLines'] as Array<any>).map(ConnectionLineToJSON)),
        'canvas': value['canvas'],
    };
}

