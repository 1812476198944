/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Symptom
 */
export interface Symptom {
    /**
     * 
     * @type {number}
     * @memberof Symptom
     */
    numberValue?: number;
    /**
     * 
     * @type {number}
     * @memberof Symptom
     */
    belongingLernaufgabe?: number;
}

/**
 * Check if a given object implements the Symptom interface.
 */
export function instanceOfSymptom(value: object): value is Symptom {
    return true;
}

export function SymptomFromJSON(json: any): Symptom {
    return SymptomFromJSONTyped(json, false);
}

export function SymptomFromJSONTyped(json: any, ignoreDiscriminator: boolean): Symptom {
    if (json == null) {
        return json;
    }
    return {
        
        'numberValue': json['numberValue'] == null ? undefined : json['numberValue'],
        'belongingLernaufgabe': json['belongingLernaufgabe'] == null ? undefined : json['belongingLernaufgabe'],
    };
}

export function SymptomToJSON(value?: Symptom | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'numberValue': value['numberValue'],
        'belongingLernaufgabe': value['belongingLernaufgabe'],
    };
}

