/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  KarmischesPotential,
} from '../models/index';
import {
    KarmischesPotentialFromJSON,
    KarmischesPotentialToJSON,
} from '../models/index';

export interface CreateKarmischesPotentialRequest {
    karmischesPotential: KarmischesPotential;
}

export interface DeleteKarmischesPotentialRequest {
    id: number;
}

export interface GetKarmischesPotentialByIdRequest {
    id: number;
}

export interface UpdateKarmischesPotentialRequest {
    id: number;
    karmischesPotential: KarmischesPotential;
}

/**
 * 
 */
export class KarmischesPotentialControllerApi extends runtime.BaseAPI {

    /**
     */
    async createKarmischesPotentialRaw(requestParameters: CreateKarmischesPotentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KarmischesPotential>> {
        if (requestParameters['karmischesPotential'] == null) {
            throw new runtime.RequiredError(
                'karmischesPotential',
                'Required parameter "karmischesPotential" was null or undefined when calling createKarmischesPotential().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/karmisches_potential`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KarmischesPotentialToJSON(requestParameters['karmischesPotential']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KarmischesPotentialFromJSON(jsonValue));
    }

    /**
     */
    async createKarmischesPotential(requestParameters: CreateKarmischesPotentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KarmischesPotential> {
        const response = await this.createKarmischesPotentialRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteKarmischesPotentialRaw(requestParameters: DeleteKarmischesPotentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteKarmischesPotential().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/karmisches_potential/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteKarmischesPotential(requestParameters: DeleteKarmischesPotentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteKarmischesPotentialRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllKarmischesPotentialeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<KarmischesPotential>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/karmisches_potential`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(KarmischesPotentialFromJSON));
    }

    /**
     */
    async getAllKarmischesPotentiale(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<KarmischesPotential>> {
        const response = await this.getAllKarmischesPotentialeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getKarmischesPotentialByIdRaw(requestParameters: GetKarmischesPotentialByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KarmischesPotential>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getKarmischesPotentialById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/karmisches_potential/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KarmischesPotentialFromJSON(jsonValue));
    }

    /**
     */
    async getKarmischesPotentialById(requestParameters: GetKarmischesPotentialByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KarmischesPotential> {
        const response = await this.getKarmischesPotentialByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateKarmischesPotentialRaw(requestParameters: UpdateKarmischesPotentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KarmischesPotential>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateKarmischesPotential().'
            );
        }

        if (requestParameters['karmischesPotential'] == null) {
            throw new runtime.RequiredError(
                'karmischesPotential',
                'Required parameter "karmischesPotential" was null or undefined when calling updateKarmischesPotential().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/karmisches_potential/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KarmischesPotentialToJSON(requestParameters['karmischesPotential']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KarmischesPotentialFromJSON(jsonValue));
    }

    /**
     */
    async updateKarmischesPotential(requestParameters: UpdateKarmischesPotentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KarmischesPotential> {
        const response = await this.updateKarmischesPotentialRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
