/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KarmischesPotential
 */
export interface KarmischesPotential {
    /**
     * 
     * @type {number}
     * @memberof KarmischesPotential
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KarmischesPotential
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof KarmischesPotential
     */
    titel: string;
}

/**
 * Check if a given object implements the KarmischesPotential interface.
 */
export function instanceOfKarmischesPotential(value: object): value is KarmischesPotential {
    if (!('text' in value) || value['text'] === undefined) return false;
    if (!('titel' in value) || value['titel'] === undefined) return false;
    return true;
}

export function KarmischesPotentialFromJSON(json: any): KarmischesPotential {
    return KarmischesPotentialFromJSONTyped(json, false);
}

export function KarmischesPotentialFromJSONTyped(json: any, ignoreDiscriminator: boolean): KarmischesPotential {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'text': json['text'],
        'titel': json['titel'],
    };
}

export function KarmischesPotentialToJSON(value?: KarmischesPotential | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'text': value['text'],
        'titel': value['titel'],
    };
}

