/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Person } from './Person';
import {
    PersonFromJSON,
    PersonFromJSONTyped,
    PersonToJSON,
} from './Person';

/**
 * 
 * @export
 * @interface GenerateResponse
 */
export interface GenerateResponse {
    /**
     * 
     * @type {string}
     * @memberof GenerateResponse
     */
    errorMessage?: string;
    /**
     * 
     * @type {Person}
     * @memberof GenerateResponse
     */
    person?: Person;
    /**
     * 
     * @type {string}
     * @memberof GenerateResponse
     */
    reportBase64?: string;
}

/**
 * Check if a given object implements the GenerateResponse interface.
 */
export function instanceOfGenerateResponse(value: object): value is GenerateResponse {
    return true;
}

export function GenerateResponseFromJSON(json: any): GenerateResponse {
    return GenerateResponseFromJSONTyped(json, false);
}

export function GenerateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'errorMessage': json['errorMessage'] == null ? undefined : json['errorMessage'],
        'person': json['person'] == null ? undefined : PersonFromJSON(json['person']),
        'reportBase64': json['reportBase64'] == null ? undefined : json['reportBase64'],
    };
}

export function GenerateResponseToJSON(value?: GenerateResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'errorMessage': value['errorMessage'],
        'person': PersonToJSON(value['person']),
        'reportBase64': value['reportBase64'],
    };
}

