/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ULebensbaumDTO } from './ULebensbaumDTO';
import {
    ULebensbaumDTOFromJSON,
    ULebensbaumDTOFromJSONTyped,
    ULebensbaumDTOToJSON,
} from './ULebensbaumDTO';
import type { EnergiebildDTO } from './EnergiebildDTO';
import {
    EnergiebildDTOFromJSON,
    EnergiebildDTOFromJSONTyped,
    EnergiebildDTOToJSON,
} from './EnergiebildDTO';
import type { PentagrammDTO } from './PentagrammDTO';
import {
    PentagrammDTOFromJSON,
    PentagrammDTOFromJSONTyped,
    PentagrammDTOToJSON,
} from './PentagrammDTO';
import type { SeelenbildDTO } from './SeelenbildDTO';
import {
    SeelenbildDTOFromJSON,
    SeelenbildDTOFromJSONTyped,
    SeelenbildDTOToJSON,
} from './SeelenbildDTO';
import type { WiederbegegnungDTO } from './WiederbegegnungDTO';
import {
    WiederbegegnungDTOFromJSON,
    WiederbegegnungDTOFromJSONTyped,
    WiederbegegnungDTOToJSON,
} from './WiederbegegnungDTO';
import type { LebensbaumDTO } from './LebensbaumDTO';
import {
    LebensbaumDTOFromJSON,
    LebensbaumDTOFromJSONTyped,
    LebensbaumDTOToJSON,
} from './LebensbaumDTO';
import type { Person } from './Person';
import {
    PersonFromJSON,
    PersonFromJSONTyped,
    PersonToJSON,
} from './Person';

/**
 * 
 * @export
 * @interface CalculateResponse
 */
export interface CalculateResponse {
    /**
     * 
     * @type {string}
     * @memberof CalculateResponse
     */
    errorMessage?: string;
    /**
     * 
     * @type {LebensbaumDTO}
     * @memberof CalculateResponse
     */
    lebensbaum?: LebensbaumDTO;
    /**
     * 
     * @type {EnergiebildDTO}
     * @memberof CalculateResponse
     */
    energiebild?: EnergiebildDTO;
    /**
     * 
     * @type {SeelenbildDTO}
     * @memberof CalculateResponse
     */
    seelenbild?: SeelenbildDTO;
    /**
     * 
     * @type {WiederbegegnungDTO}
     * @memberof CalculateResponse
     */
    wiederbegegnung?: WiederbegegnungDTO;
    /**
     * 
     * @type {PentagrammDTO}
     * @memberof CalculateResponse
     */
    pentagramm?: PentagrammDTO;
    /**
     * 
     * @type {Person}
     * @memberof CalculateResponse
     */
    person?: Person;
    /**
     * 
     * @type {ULebensbaumDTO}
     * @memberof CalculateResponse
     */
    ulebensbaum?: ULebensbaumDTO;
}

/**
 * Check if a given object implements the CalculateResponse interface.
 */
export function instanceOfCalculateResponse(value: object): value is CalculateResponse {
    return true;
}

export function CalculateResponseFromJSON(json: any): CalculateResponse {
    return CalculateResponseFromJSONTyped(json, false);
}

export function CalculateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalculateResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'errorMessage': json['errorMessage'] == null ? undefined : json['errorMessage'],
        'lebensbaum': json['lebensbaum'] == null ? undefined : LebensbaumDTOFromJSON(json['lebensbaum']),
        'energiebild': json['energiebild'] == null ? undefined : EnergiebildDTOFromJSON(json['energiebild']),
        'seelenbild': json['seelenbild'] == null ? undefined : SeelenbildDTOFromJSON(json['seelenbild']),
        'wiederbegegnung': json['wiederbegegnung'] == null ? undefined : WiederbegegnungDTOFromJSON(json['wiederbegegnung']),
        'pentagramm': json['pentagramm'] == null ? undefined : PentagrammDTOFromJSON(json['pentagramm']),
        'person': json['person'] == null ? undefined : PersonFromJSON(json['person']),
        'ulebensbaum': json['ulebensbaum'] == null ? undefined : ULebensbaumDTOFromJSON(json['ulebensbaum']),
    };
}

export function CalculateResponseToJSON(value?: CalculateResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'errorMessage': value['errorMessage'],
        'lebensbaum': LebensbaumDTOToJSON(value['lebensbaum']),
        'energiebild': EnergiebildDTOToJSON(value['energiebild']),
        'seelenbild': SeelenbildDTOToJSON(value['seelenbild']),
        'wiederbegegnung': WiederbegegnungDTOToJSON(value['wiederbegegnung']),
        'pentagramm': PentagrammDTOToJSON(value['pentagramm']),
        'person': PersonToJSON(value['person']),
        'ulebensbaum': ULebensbaumDTOToJSON(value['ulebensbaum']),
    };
}

