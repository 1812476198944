import React, { useState, useEffect, ChangeEvent } from "react";
import { Table, Input, Button, FormElement, Modal, Text, Textarea,Grid, User, useModal } from "@nextui-org/react";
import * as runtime from "../../../client/runtime";
import { LernaufgabenControllerApi } from "../../../client";
import { BaseAPI } from "../../../client/runtime";
import userIcon from "@components/navbar/icons/res/user-icon.jpg";
import { toast } from "react-toastify";
import { DEFAULT_TOASTER_OPTIONS } from "@utils/constants";
import { renderValue } from "@components/routes/comps/AdminTable";


interface AdminTableUpdateModalProps {
  bindings: any;
  selectedRow: any;
  onClose: () => void;
  controllerName: runtime.BaseAPI;
  updateMethod: string;
  updateDataField: string;
}

interface RowData {
  [key: string]: string;
}
export const AdminTableUpdateModal: React.FC<AdminTableUpdateModalProps> = ({ bindings, selectedRow, onClose, controllerName, updateMethod, updateDataField }) => {

  const [initialRowData, setInitialRowData] = useState<RowData>({  }); // Zustand für die ursprünglichen Daten
  const [rowData, setRowData] = useState<RowData>({}); // Zustand zum Verwalten der Daten der Textareas

  useEffect(() => {
    if (selectedRow) {
      setInitialRowData(selectedRow);
      setRowData(selectedRow);
    }
  }, [selectedRow]);

  // Update des Zustands, wenn sich der Inhalt der Textarea ändert
  const handleTextareaChange = (key: string, value: string) => {
    if (initialRowData && initialRowData[key] !== value) {
      setRowData((prevData) => ({
        ...prevData,
        [key]: value,
      }));
    } else if (!initialRowData[key]) {
      setRowData((prevData) => ({
        ...prevData,
        [key]: value,
      }));
    }
  };

  const handleOnSave = async () => {
    try {
      const requestData: any = {
        id: rowData.id,
        [updateDataField]: {
          ...rowData // Hier kopierst du alle Felder aus rowData in unsichtbarerLebensbaumSephiroth
        }
      };

      await (controllerName as any)[updateMethod](requestData);
      toast.success("Die Änderungen wurden gespeichert.", DEFAULT_TOASTER_OPTIONS);
      console.log("Die geänderten Daten:");
      console.log(rowData);
      onClose();
    } catch (error) {
      console.error("Fehler beim Speichern der Änderungen:", error);
      toast.error("Fehler beim Speichern der Änderungen.", DEFAULT_TOASTER_OPTIONS);
    }
  };

  return (
    <Modal
      closeButton
      scroll
      width="60%"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      {...bindings}
    >
      <Modal.Header>
        <Text id="modal-title" size={18}>
          Anpassen der Reihe
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Text h3>{updateMethod}</Text>
        {selectedRow && (
          <div>
            {Object.keys(selectedRow).map(key => (
              <>
              <Textarea
                disabled={key === "id"}
                fullWidth
                initialValue={renderValue(selectedRow[key])}
                label={key}
                onChange={(e) => handleTextareaChange(key, e.target.value)}
              />
              </>
            ))}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button auto flat color={"error"} onPress={onClose}>
          Änderungen verwefen
        </Button>
        <Button auto flat color={"success"} onPress={handleOnSave}>
          Speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
};