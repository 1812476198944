/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LegendEntry } from './LegendEntry';
import {
    LegendEntryFromJSON,
    LegendEntryFromJSONTyped,
    LegendEntryToJSON,
} from './LegendEntry';

/**
 * 
 * @export
 * @interface ULebensbaumDTO
 */
export interface ULebensbaumDTO {
    /**
     * 
     * @type {Array<LegendEntry>}
     * @memberof ULebensbaumDTO
     */
    legendEntries?: Array<LegendEntry>;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof ULebensbaumDTO
     */
    lineCircles?: Array<Array<number>>;
}

/**
 * Check if a given object implements the ULebensbaumDTO interface.
 */
export function instanceOfULebensbaumDTO(value: object): value is ULebensbaumDTO {
    return true;
}

export function ULebensbaumDTOFromJSON(json: any): ULebensbaumDTO {
    return ULebensbaumDTOFromJSONTyped(json, false);
}

export function ULebensbaumDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ULebensbaumDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'legendEntries': json['legendEntries'] == null ? undefined : ((json['legendEntries'] as Array<any>).map(LegendEntryFromJSON)),
        'lineCircles': json['lineCircles'] == null ? undefined : json['lineCircles'],
    };
}

export function ULebensbaumDTOToJSON(value?: ULebensbaumDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'legendEntries': value['legendEntries'] == null ? undefined : ((value['legendEntries'] as Array<any>).map(LegendEntryToJSON)),
        'lineCircles': value['lineCircles'],
    };
}

