/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignUpRequest
 */
export interface SignUpRequest {
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    email: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof SignUpRequest
     */
    role?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    password: string;
}

/**
 * Check if a given object implements the SignUpRequest interface.
 */
export function instanceOfSignUpRequest(value: object): value is SignUpRequest {
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    return true;
}

export function SignUpRequestFromJSON(json: any): SignUpRequest {
    return SignUpRequestFromJSONTyped(json, false);
}

export function SignUpRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignUpRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'username': json['username'],
        'email': json['email'],
        'role': json['role'] == null ? undefined : json['role'],
        'password': json['password'],
    };
}

export function SignUpRequestToJSON(value?: SignUpRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'username': value['username'],
        'email': value['email'],
        'role': value['role'] == null ? undefined : Array.from(value['role'] as Set<any>),
        'password': value['password'],
    };
}

