/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserInfoResponse
 */
export interface UserInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof UserInfoResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponse
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponse
     */
    email?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserInfoResponse
     */
    roles?: Array<string>;
}

/**
 * Check if a given object implements the UserInfoResponse interface.
 */
export function instanceOfUserInfoResponse(value: object): value is UserInfoResponse {
    return true;
}

export function UserInfoResponseFromJSON(json: any): UserInfoResponse {
    return UserInfoResponseFromJSONTyped(json, false);
}

export function UserInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfoResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'username': json['username'] == null ? undefined : json['username'],
        'email': json['email'] == null ? undefined : json['email'],
        'roles': json['roles'] == null ? undefined : json['roles'],
    };
}

export function UserInfoResponseToJSON(value?: UserInfoResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'username': value['username'],
        'email': value['email'],
        'roles': value['roles'],
    };
}

