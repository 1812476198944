/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LebensbaumWege
 */
export interface LebensbaumWege {
    /**
     * 
     * @type {number}
     * @memberof LebensbaumWege
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LebensbaumWege
     */
    lernaufgabe: string;
    /**
     * 
     * @type {string}
     * @memberof LebensbaumWege
     */
    talent: string;
}

/**
 * Check if a given object implements the LebensbaumWege interface.
 */
export function instanceOfLebensbaumWege(value: object): value is LebensbaumWege {
    if (!('lernaufgabe' in value) || value['lernaufgabe'] === undefined) return false;
    if (!('talent' in value) || value['talent'] === undefined) return false;
    return true;
}

export function LebensbaumWegeFromJSON(json: any): LebensbaumWege {
    return LebensbaumWegeFromJSONTyped(json, false);
}

export function LebensbaumWegeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LebensbaumWege {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'lernaufgabe': json['lernaufgabe'],
        'talent': json['talent'],
    };
}

export function LebensbaumWegeToJSON(value?: LebensbaumWege | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'lernaufgabe': value['lernaufgabe'],
        'talent': value['talent'],
    };
}

