import { Button, Card, Spacer, Text, Container, Input, Loading, Grid } from "@nextui-org/react";
import { DEFAULT_TOASTER_OPTIONS } from "@utils/constants";
import { useState } from "react";
import { toast } from "react-toastify";
import { CalculateControllerApi } from "../../client";
import { useHookstate } from "@hookstate/core";
import { calculationHistoryState } from "@states/CalculationHistoryState";
import { apiConfig } from "@api/configuration";
import { CalculationHistoryTable } from "@components/routes/comps/CalculationHistoryTable";
import { calculationRequestHistoryState } from "@states/CalculationRequestHistoryState";
import { HiDownload } from "react-icons/hi";

type InputState = "default" | "primary" | "secondary" | "success" | "warning" | "error";

const b64toBlob = (b64Data: string, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
      
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

export const Eingabe = () => {
    const [vorname, setVorname] = useState("Max");
    const [nachname, setNachname] = useState("Mustermann");
    const [rufname, setRufname] = useState("Ma");
    const [ehename, setEhename] = useState("");
    const [berechneUnsichtbarenLebensbaum, setBerechneUnsichtbarenLebensbaum] = useState(true);
    const [benutzeVornameAlsRufname, setBenutzeVornameAlsRufname] = useState(false);
    const [benutzeNachnameAlsEhename, setBenutzeNachnameAlsEhename] = useState(true);
    const [date, setDate] = useState(new Date("1994-04-06"));

    const calculationState = useHookstate(calculationHistoryState);
    const calcRequestHistoryState = useHookstate(calculationRequestHistoryState);
    const [vornameState, setVornameState] = useState<InputState>("default");
    const [nachnameState, setNachnameState] = useState<InputState>("default");
    const [dateState, setDateState] = useState<InputState>("default");
    const [buttonLoading, setButtonLoading] = useState(false);
    const [reportLoading, setReportLoading] = useState(false);

    const api = new CalculateControllerApi(apiConfig);


    const downloadReport = () => {
        setReportLoading(true);
        const calculateParams = {
        calculateRequest: {
            input: {
                vorname: vorname,
                nachname: nachname,
                geburtsdatum: date,
                berechneUnsichtbarenLebensbaum: berechneUnsichtbarenLebensbaum,
                rufname: vorname, // Vorname als Rufname verwenden
                ehename: nachname, // Nachname als Ehename
                activation: "",
                benutzeVornameAlsRufname: benutzeVornameAlsRufname,
                benutzeNachnameAlsEhename: benutzeNachnameAlsEhename
            }
        }
    };

    api.generateReport(calculateParams)
        .then(response => {
            if (!response.reportBase64) {
                console.error('Report is empty:', response);
                return;
            }

        let calcVorname = calculateParams.calculateRequest.input.vorname;
        let calcNachname = calculateParams.calculateRequest.input.nachname;

        const blob = b64toBlob(response.reportBase64, 'application/pdf');
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${calcVorname}-${calcNachname}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Generate report error:', error);
      })
      .finally(() => setReportLoading(false));
  }


  const handleSelectionChange = (selectedKeys: Set<React.Key>) => {
    const selectedKey = Array.from(selectedKeys)[0]; // Hier wird der erste Eintrag aus dem Set genommen
    const numberValue = parseInt(selectedKey as string, 10); // String in Zahl umwandeln

    const selectedRequest = calcRequestHistoryState.get()[numberValue];
    console.log(numberValue);


    if (selectedRequest) {
      // Prüfe, ob die Werte vorhanden sind, bevor du sie setzt
      if (selectedRequest.calculateRequest.input?.vorname) {
        setVorname(selectedRequest.calculateRequest.input.vorname);
      }
      if (selectedRequest.calculateRequest.input?.nachname) {
        setNachname(selectedRequest.calculateRequest.input.nachname);
      }
      if (selectedRequest.calculateRequest.input?.geburtsdatum) {
        console.log(selectedRequest.calculateRequest.input.geburtsdatum);
        setDate(new Date(selectedRequest.calculateRequest.input.geburtsdatum));
      }
    }

  };

    const berechnen = () => {
        setButtonLoading(true);
      const calculateParams = {
        calculateRequest: {
          input: {
            vorname: vorname,
            nachname: nachname,
            geburtsdatum: date,
            berechneUnsichtbarenLebensbaum: berechneUnsichtbarenLebensbaum,
            rufname: vorname, // Vorname als Rufname verwenden
            ehename: nachname, // Nachname als Ehename
            activation: "",
            benutzeVornameAlsRufname: benutzeVornameAlsRufname,
            benutzeNachnameAlsEhename: benutzeNachnameAlsEhename
          }
        }
      };

      api.calculate(calculateParams)
            .then(response => {
                console.log('Calculate response (über UI):', response);
                calculationHistoryState.set((prevArray) => [
                    ...prevArray,
                    response
                ]);
              calculationRequestHistoryState.set(prevArray => [
                ...prevArray,
                calculateParams
              ]);
                toast.success("Die Berechnung wurde erfolgreich durchgeführt.", DEFAULT_TOASTER_OPTIONS);
            })
            .catch(error => {
                console.error('Calculate error:', error);
            }).finally(() => setButtonLoading(false));
    };

    const isInputValid = () => {
        if (nachnameState === "error" || vornameState === "error" || dateState === "error")
            return false;
        else
            return true;
    }

    const displayErrorToast = () => {
        if (vornameState === "error") {
            toast.error("Bitte geben Sie einen Vornamen ein.", DEFAULT_TOASTER_OPTIONS);
        }

        if (nachnameState === "error") {
            toast.error("Bitte geben Sie einen Nachnamen ein.", DEFAULT_TOASTER_OPTIONS);
        }

        if (dateState === "error") {
            toast.error("Bitte geben Sie ein gültiges Geburtsdatum ein.", DEFAULT_TOASTER_OPTIONS);
        }
    }

    return (
        <Container css={{maxWidth: "1400px"}}>
            <Spacer />
            <Spacer />
            {/* Heading */}
            <Text
                h1
                size={48}
                css={{ textGradient: "45deg, $blue600 -20%, $pink600 50%", marginBottom: 0}}
                weight="bold">
                Eingabe
            </Text>

            <Spacer />

          <Grid.Container gap={2}>
            <Grid xs={12} md={4}>
              <Card>
                <Card.Body>
                  <Input
                    initialValue={vorname}
                    value={vorname}
                    status={vornameState}
                    onInput={({target}) => {
                      const value = (target as HTMLInputElement).value;
                      setVorname(value);

                      if (value.length === 0 || value.match("[^A-Za-zÄäÜüÖöß\\- ]") != null)
                        setVornameState("error");
                      else
                        setVornameState("default");
                    }}
                    css={{display: "flex"}}
                    label="Vorname"
                    placeholder="Max"
                  />

                  <Spacer/>
                  <Input
                    initialValue={nachname}
                    value={nachname}
                    status={nachnameState}
                    onInput={({target}) => {
                      const value = (target as HTMLInputElement).value;
                      setNachname(value);

                      if (value.length === 0 || value.match("[^A-Za-zÄäÜüÖöß\\- ]") != null)
                        setNachnameState("error");
                      else
                        setNachnameState("default");
                    }}
                    css={{display: "flex"}}
                    label="Nachname"
                    placeholder="Mustermann"
                  />

                  <Spacer/>
                  <Input
                    initialValue={date.toISOString().split("T")[0]}
                    value={date.toISOString().split("T")[0]}
                    status={dateState}
                    onInput={({target}) => {
                      const value = (target as HTMLInputElement).value;

                      if (value.length === 0 || value.toString() == "Invalid Date" || new Date(value) > new Date()) {
                        setDateState("error");
                      } else {
                        setDate(new Date(value));
                        setDateState("default");
                      }
                    }}
                    css={{display: "flex"}}
                    label="Date"
                    type="date"
                  />

                    <Spacer/>
                    <div
                        onClick={
                            isInputValid()
                                ? undefined
                                : displayErrorToast
                        }
                    >
                        <Button
                            onClick={berechnen}
                            disabled={!isInputValid()}
                            style={{width: "100%"}}
                        >
                          {buttonLoading ? <Loading type="points" color="currentColor" size="sm" /> : 'Berechnung starten'}
                        </Button>
                    </div>

                    <Spacer/>
                    <div
                        onClick={
                            isInputValid()
                                ? undefined
                                : displayErrorToast
                        }
                    >
                        <Button
                            onClick={downloadReport}
                            disabled={!isInputValid()}
                            style={{width: "100%"}}
                            color="secondary"
                            iconRight={<HiDownload />}
                        >
                          {reportLoading ? <Loading type="points" color="currentColor" size="sm" /> : 'Bericht herunterladen'}
                        </Button>
                    </div>
                </Card.Body>
              </Card>
            </Grid>
            <Grid md={8} css={{
                '@xs': { display: 'none' },
                '@sm': { display: 'none' },
            }}>
                  <CalculationHistoryTable onSelectionChange={handleSelectionChange} />
            </Grid>

          </Grid.Container>
        </Container>
    );
}