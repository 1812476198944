import { hookstate, ImmutableArray } from "@hookstate/core";
import { localstored } from "@hookstate/localstored";
import {
    LOCAL_STORAGE_LOGGED_IN_USER_KEY
} from "@utils/constants";

interface LoggedInUser {
    id: number;
    username: string;
    email: string;
    roles: ImmutableArray<string>;
}

export const initialLoggedInUserState: LoggedInUser = {
    id: 0,
    username: '',
    email: '',
    roles: []
};

export const loggedInUserState = hookstate(
  initialLoggedInUserState,
    localstored({ key: LOCAL_STORAGE_LOGGED_IN_USER_KEY })
);
