import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { NextUIProvider } from "@nextui-org/react";
import { Layout } from "@components/navbar/Layout";
import { NavigationBar } from "@components/navbar/NavigationBar";
import { useHookstate } from "@hookstate/core";
import { themeState } from "@states/ThemeState";
import { LIGHT_THEME_NAME } from "@utils/constants";
import { lightTheme } from "@themes/light";
import { darkTheme } from "@themes/dark";
import { Theme, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Info } from "./routes/Info";
import { Lebensbaum } from "./routes/Lebensbaum";
import { Eingabe } from "./routes/Eingabe";
import { Energiebild } from "./routes/Energiebild";
import { Seelenbild } from "./routes/Seelenbild";
import { KarmischeWiederbegegnung } from "./routes/KarmischeWiederbegegnung";
import { Admin } from "@components/routes/Admin";
import { Login } from "@components/routes/Login";
import { loggedInUserState } from "@states/LoggedInUserState";
import { Pentagramm } from "./routes/Pentagramm";
//import { Login } from "./routes/Login"; // Beispiel für die Einlogroute

export const queryClient = new QueryClient();

export const App = () => {
    const theme = useHookstate(themeState);
    const selectedTheme = theme.get().name === LIGHT_THEME_NAME ? lightTheme : darkTheme;
    const loggedInUser = useHookstate(loggedInUserState).get();
    const isLoggedIn = !!loggedInUser.id && loggedInUser.username !== "" && loggedInUser.email !== "" && loggedInUser.roles.length > 0;
    const isAdmin = loggedInUser && loggedInUser.roles.includes("ROLE_ADMIN");

    return (
      // The Query Client Provider enables TanStack queries
      <QueryClientProvider client={queryClient}>
          {/* Next UI Provider for the theme */}
          <NextUIProvider theme={selectedTheme.factory}>
              {/* Display toasts from anywhere within the app */}
              <ToastContainer style={{ zIndex: 10000 }} theme={selectedTheme.name as Theme} />
              {/* Main layout of the app */}
              <Layout>
                  <BrowserRouter>
                      {/* Nur die NavigationBar anzeigen, wenn der Benutzer eingeloggt ist */}
                      {isLoggedIn && <NavigationBar />}
                      <Routes>
                          {/* Private Routen */}
                          {isLoggedIn ? (
                            <>
                                <Route path="/" element={<Eingabe />} />
                                <Route path="/info" element={<Info />} />
                                <Route path="/lebensbaum" element={<Lebensbaum />} />
                                <Route path="/energiebild" element={<Energiebild />} />
                                <Route path="/seelenbild" element={<Seelenbild />} />
                                <Route path="/karmische-wiederbegegnung" element={<KarmischeWiederbegegnung />} />
                                <Route path="/pentagramm" element={<Pentagramm />} />
                                {isAdmin && <Route path="/admin" element={<Admin />} />}
                            </>
                          ) : (
                            <>
                                <Route path="/login" element={<Login />} />
                                <Route path="*" element={<Navigate to="/login" />} />
                            </>
                          )}
                          {isLoggedIn && <Route path="/login" element={<Navigate to="/" />} />}
                      </Routes>
                  </BrowserRouter>
              </Layout>
          </NextUIProvider>
      </QueryClientProvider>
    );
};
