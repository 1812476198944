import { Modal, useModal, Button, Text, Navbar, Grid, User } from "@nextui-org/react";
import userIcon from "@components/navbar/icons/res/user-icon.jpg";

export const InfoModal = () => {
    const { setVisible, bindings } = useModal();
    return (
        <div>
            <Navbar.Link color="inherit" href="#" onPress={() => setVisible(true)}>
                Über uns
            </Navbar.Link>
            <Modal
                scroll
                width="600px"
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                {...bindings}>
                <Modal.Header>
                    <Text id="modal-title" size={18}>
                        Informationen über uns
                    </Text>
                </Modal.Header>
                <Modal.Body>
                    <Text h3>Über uns</Text>
                    <Text blockquote css={{ marginTop: "0px" }}>
                        Hier folgt eine Beschreibung.
                    </Text>
                    <Text h3>Ansprechpartner</Text>
                    <Grid.Container gap={2}>
                        <Grid>
                            <User
                                squared
                                name="Dipl.-Ing. Ulrich Uffelmann"
                                src={userIcon}/>
                        </Grid>
                    </Grid.Container>
                    <Text h3>Entwickler der Webseite</Text>
                    <Grid.Container gap={2}>
                        <Grid xs={12} sm={6}>
                            <User name="Kevin Pfeifer" squared src={userIcon} />
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <User name="Florian Sommerfeld" squared src={userIcon} />
                        </Grid>
                    </Grid.Container>
                    <Text h3>Impressum</Text>
                    <Text blockquote css={{ marginTop: "0px" }}>
                        Angaben gemäß § 5 TMG
                        <br/>
                        <br/>
                        Max Muster
                        Musterweg
                        12345 Musterstadt
                        <br/>
                        <br/>
                        <b>Vertreten durch:</b>
                        Ulrich Uffelmann
                        <br/>
                        <b>Kontakt:</b> <br/>
                            <b>Telefon: 01234-789456</b><br/>
                                <b>Fax: 1234-56789</b><br/>
                                    <b>E-Mail: max@muster.de</b>
                        <br/>
                        <br/>
                        <b>Umsatzsteuer-ID:</b>
                        Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: Musterustid.
                        <br/>
                        <br/>
                        <b>Wirtschafts-ID:</b>
                        Musterwirtschaftsid
                        <br/>
                        <br/>
                        <b>Aufsichtsbehörde:</b>
                        Musteraufsicht Musterstadt
                        <br/>
                        <br/>
                        <b>Haftungsausschluss:</b>
                        <br/>
                        <br/>
                        <b>Datenschutz</b>
                        <br/>
                        Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
                        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
                        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
                    </Text>
                </Modal.Body>
                <Modal.Footer>
                    <Button auto onPress={() => setVisible(false)}>
                        Schließen
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
