import {ToastOptions} from "react-toastify";


export const DARK_THEME_NAME = "dark";
export const LIGHT_THEME_NAME = "light";
export const LOCAL_STORAGE_CALCULATION_RESULT_KEY = "kabbala-calc-result";
export const LOCAL_STORAGE_CALCULATION_REQUEST_HISTORY_KEY = "kabbala-calc-request-history";
export const LOCAL_STORAGE_LOGGED_IN_USER_KEY = "logged-in-user";

export const LOCAL_STORAGE_THEME_KEY = "theme";
export const LOCAL_STORAGE_CALCULATION_HISTORY_KEY = "calculation-history";

export const DEFAULT_TOASTER_OPTIONS: ToastOptions = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
}
