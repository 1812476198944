interface LebensbaumSvgProps {
    test: string,
    weg1: number,
    weg2:number,
    weg3:number,
  handleLebensbaumWegClick: any,
  handlePlanetClick: any
}

export const LebensbaumSvg = ({ test, weg1, weg2, weg3,handleLebensbaumWegClick,handlePlanetClick }: LebensbaumSvgProps) => {

  const highlightedWege = [
    weg1 ?? -1, // set to -1 if null or undefined - this way weg0 will not be highlighted
    weg2 ?? -1,
    weg3 ?? -1
  ];
  const defaultColor = "#DFDFDF";
  const highlightColor = "#756AB6";
  const weg1Color = "#AC87C5";
  const weg2Color = "#E0AED0";
  const weg3Color = "#FFE5E5";

  const getWegColor = (wegNummer: number) => {
    if (wegNummer === weg1) {
      return weg1Color;
    } else if (wegNummer === weg2) {
      return weg2Color;
    } else if (wegNummer === weg3) {
      return weg3Color;
    } else {
      return defaultColor;
    }
  };

  const wegeToPlanetenMappingRecord: Record<number, number[]> = {
    //weg_i: [planet1, planet2],
    0: [1, 2],   // weg0: Der Narr verbindet Kether (1) und Chokmah (2)
    1: [3, 1],   // ...
    2: [1, 6],
    3: [3, 2],
    4: [6, 2],
    5: [2, 4],
    6: [3, 6],
    7: [3, 5],
    8: [5, 4],
    9: [6, 4],
    10: [4, 7],
    11: [5, 6],
    12: [5, 8],
    13: [6, 7],
    14: [9, 6],
    15: [8, 6],
    16: [8, 7],
    17: [9, 7],
    18: [10, 7],
    19: [8, 9],
    20: [8, 10],
    21: [10, 9],
  };

  const getPlanetFill = (planetNum: number) => {
    // Prüfen, ob der Planet in einem der aktiven Wege liegt
    const activeWege = highlightedWege.filter(weg => typeof weg === 'number') as number[];

    for (const weg of activeWege) {
      if (wegeToPlanetenMappingRecord[weg]?.includes(planetNum)) {
        return highlightColor; // Wenn ja, hervorgehobene Farbe zurückgeben
      }
    }
    return defaultColor; // Ansonsten Standardfarbe zurückgeben
  };

  const handleWegClick = (event: React.MouseEvent<SVGLineElement, MouseEvent>) => {
    const id = event.currentTarget.id;
    handleLebensbaumWegClick(id);
  };

  const handleClickOnPlanet = (event: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
    const id = event.currentTarget.id;
    handlePlanetClick(id);
  };

  return(
    <svg width="100%" height="100%" viewBox="0 0 732 601" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="100%" height="100%" rx="15" fill="white"/>
      <line onClick={handleWegClick} x1="367.357" y1="45.7739" x2="550.357" y2="122.774" stroke={getWegColor(0)} strokeWidth={7} id="0"/>{/* weg0: Der Narr */}
      <line onClick={handleWegClick} x1="367.471" y1="47.1758" x2="190.471" y2="129.176" stroke={getWegColor(1)} strokeWidth={7} id="1"/>{/* weg1: Der Magier */}
      <line onClick={handleWegClick} x1="189.5" y1="259" x2="189.5" y2="126" stroke={getWegColor(7)} strokeWidth={7} id="7"/>{/* weg7: Der Wagen */}
      <line onClick={handleWegClick} x1="549.5" y1="249" x2="549.5" y2="116" stroke={getWegColor(5)} strokeWidth={7} id="5"/>{/* weg5: Hierophant */}
      <line onClick={handleWegClick} x1="369.5" y1="549" x2="369.5" y2="44" stroke={getWegColor(2)} strokeWidth={7} id="2"/>{/* weg2: Die Hohepriesterin */}
      <line onClick={handleWegClick} x1="370.552" y1="551.502" x2="186.552" y2="371.502" stroke={getWegColor(20)} strokeWidth={7} id="20"/>{/* weg20: Das Gericht */}
      <line onClick={handleWegClick} x1="364.713" y1="442.255" x2="187.713" y2="372.255" stroke={getWegColor(19)} strokeWidth={7} id="19"/>{/* weg19: Die Sonne */}
      <line onClick={handleWegClick} x1="367.74" y1="441.735" x2="551.74" y2="370.735" stroke={getWegColor(17)} strokeWidth={7} id="17"/>{/* weg17: Der Stern */}
      <line onClick={handleWegClick} x1="374.3" y1="298.75" x2="554.3" y2="370.75" stroke={getWegColor(13)} strokeWidth={7} id="13"/>{/* weg13: Der Tod */}
      <line onClick={handleWegClick} x1="367.309" y1="299.246" x2="186.309" y2="372.246" stroke={getWegColor(15)} strokeWidth={7} id="15"/>{/* weg15: Der Teufel */}
      <line onClick={handleWegClick} x1="367.309" y1="299.246" x2="186.309" y2="259" stroke={getWegColor(11)} strokeWidth={7} id="11"/>{/* weg11: Die Kraft */}
      <line onClick={handleWegClick} x1="367.309" y1="299.246" x2="549.5" y2="259" stroke={getWegColor(9)} strokeWidth={7} id="9"/>{/* weg9: Der Eremit */}
      <line onClick={handleWegClick} x1="368.589" y1="301.537" x2="186.589" y2="128.537" stroke={getWegColor(6)} strokeWidth={7} id="6"/>{/* weg6: Die Liebenden */}
      <line onClick={handleWegClick} x1="368.561" y1="296.49" x2="546.561" y2="123.49" stroke={getWegColor(4)} strokeWidth={7} id="4"/>{/* weg4: Der Herrscher */}
      <line onClick={handleWegClick} x1="370.525" y1="546.525" x2="550.525" y2="366.525" stroke={getWegColor(18)}strokeWidth={7} id="18"/>{/* weg18: Der Mond */}
      <line onClick={handleWegClick} x1="189" y1="126" x2="553" y2="126" stroke={getWegColor(3)} strokeWidth={7} id="3"/>{/* weg3: Die Herrscherin */}
      <line onClick={handleWegClick} x1="189" y1="257.5" x2="553" y2="257.5" stroke={getWegColor(8)} strokeWidth={7} id="8"/>{/* weg8: Die Gerechtigkeit */}
      <line onClick={handleWegClick} x1="189" y1="370.5" x2="553" y2="370.5" stroke={getWegColor(16)} strokeWidth={7} id="16"/>{/* weg16: Der Turm */}
      <line onClick={handleWegClick} x1="189.5" y1="369" x2="189.5" y2="236" stroke={getWegColor(12)} strokeWidth={7} id="12"/>{/* weg12: Der Gehängte */}
      <line onClick={handleWegClick} x1={549.5} y1={389} x2={549.5} y2={256} stroke={getWegColor(10)} strokeWidth={7} id="10"/>{/* weg10: Schicksalsrad */}
      <circle onClick={handleClickOnPlanet} cx="369" cy="49" r="25" fill={getPlanetFill(1)} id="1"/>{/* planet1: Kether */}
      <circle onClick={handleClickOnPlanet} cx="189" cy="126" r="25" fill={getPlanetFill(3)} id="3"/>{/* planet3: Binah */}
      <circle onClick={handleClickOnPlanet} cx="549" cy="259" r="25" fill={getPlanetFill(4)} id="4"/>{/* planet4: Chesed */}
      <circle onClick={handleClickOnPlanet} cx="189" cy="259" r="25" fill={getPlanetFill(5)} id="5"/>{/* planet5: Geburah */}
      <circle onClick={handleClickOnPlanet} cx="189" cy="369" r="25" fill={getPlanetFill(8)} id="8"/>{/* planet8: Hod */}
      <circle onClick={handleClickOnPlanet} cx="369" cy="299" r="25" fill={getPlanetFill(6)} id="6"/>{/* planet6: Tipheret */}
      <circle onClick={handleClickOnPlanet} cx="369" cy="439" r="25" fill={getPlanetFill(9)} id="9"/>{/* planet9: Jesod */}
      <circle onClick={handleClickOnPlanet} cx="369" cy="549" r="25" fill={getPlanetFill(10)} id="10"/>{/* planet10: Malkuth */}
      <circle onClick={handleClickOnPlanet} cx="549" cy="126" r="25" fill={getPlanetFill(2)} id="2"/>{/* planet2: Chokmah */}
      <circle onClick={handleClickOnPlanet} cx="549" cy="369" r="25" fill={getPlanetFill(7)} id="7">
      </circle>{/* planet7: Nezach */}
      <text fill="black" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Roboto" fontSize={14} letterSpacing="0px">
            <tspan x={61} y={128.785}>{/*Binah-Neptun: {test}*/}</tspan>
        </text>
        <text fill="black" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Roboto" fontSize={14} letterSpacing="0px">
            <tspan x={59} y={260.785}>{/*Geburah-Mars*/}</tspan>
        </text>
        <text fill="black" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Roboto" fontSize={14} letterSpacing="0px">
            <tspan x={590} y={260.785}>{/*Chesed-Jupiter*/}</tspan>
        </text>
        <text fill="black" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Roboto" fontSize={14} letterSpacing="0px">
            <tspan x={593} y={373.785}>{/*Nezach-Venus*/}</tspan>
        </text>
        <text fill="black" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Roboto" fontSize={14} letterSpacing="0px">
            <tspan x={67} y={370.785}>{/*Hod-Merkur*/}</tspan>
        </text>
        <text fill="black" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Roboto" fontSize={14} letterSpacing="0px">
            <tspan x={164} y={319.785}>{/*12*/}</tspan>
        </text>
        <text fill="black" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Roboto" fontSize={14} letterSpacing="0px">
            <tspan x={281} y={197.785}>{/*6*/}</tspan>
        </text>
        <text fill="black" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Roboto" fontSize={14} letterSpacing="0px">
            <tspan x={560} y={320.785}>{/*10*/}</tspan>
        </text>
    </svg>
  );
}
