/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LegendEntryColorColorSpace } from './LegendEntryColorColorSpace';
import {
    LegendEntryColorColorSpaceFromJSON,
    LegendEntryColorColorSpaceFromJSONTyped,
    LegendEntryColorColorSpaceToJSON,
} from './LegendEntryColorColorSpace';

/**
 * 
 * @export
 * @interface LegendEntryColor
 */
export interface LegendEntryColor {
    /**
     * 
     * @type {number}
     * @memberof LegendEntryColor
     */
    red?: number;
    /**
     * 
     * @type {number}
     * @memberof LegendEntryColor
     */
    green?: number;
    /**
     * 
     * @type {number}
     * @memberof LegendEntryColor
     */
    blue?: number;
    /**
     * 
     * @type {number}
     * @memberof LegendEntryColor
     */
    alpha?: number;
    /**
     * 
     * @type {number}
     * @memberof LegendEntryColor
     */
    rgb?: number;
    /**
     * 
     * @type {LegendEntryColorColorSpace}
     * @memberof LegendEntryColor
     */
    colorSpace?: LegendEntryColorColorSpace;
    /**
     * 
     * @type {number}
     * @memberof LegendEntryColor
     */
    transparency?: number;
}

/**
 * Check if a given object implements the LegendEntryColor interface.
 */
export function instanceOfLegendEntryColor(value: object): value is LegendEntryColor {
    return true;
}

export function LegendEntryColorFromJSON(json: any): LegendEntryColor {
    return LegendEntryColorFromJSONTyped(json, false);
}

export function LegendEntryColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegendEntryColor {
    if (json == null) {
        return json;
    }
    return {
        
        'red': json['red'] == null ? undefined : json['red'],
        'green': json['green'] == null ? undefined : json['green'],
        'blue': json['blue'] == null ? undefined : json['blue'],
        'alpha': json['alpha'] == null ? undefined : json['alpha'],
        'rgb': json['rgb'] == null ? undefined : json['rgb'],
        'colorSpace': json['colorSpace'] == null ? undefined : LegendEntryColorColorSpaceFromJSON(json['colorSpace']),
        'transparency': json['transparency'] == null ? undefined : json['transparency'],
    };
}

export function LegendEntryColorToJSON(value?: LegendEntryColor | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'red': value['red'],
        'green': value['green'],
        'blue': value['blue'],
        'alpha': value['alpha'],
        'rgb': value['rgb'],
        'colorSpace': LegendEntryColorColorSpaceToJSON(value['colorSpace']),
        'transparency': value['transparency'],
    };
}

