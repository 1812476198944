/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SeelenbildZahlen,
} from '../models/index';
import {
    SeelenbildZahlenFromJSON,
    SeelenbildZahlenToJSON,
} from '../models/index';

export interface CreateSeelenbildZahlenRequest {
    seelenbildZahlen: SeelenbildZahlen;
}

export interface DeleteSeelenbildZahlenRequest {
    id: number;
}

export interface GetSeelenbildZahlenByIdRequest {
    id: number;
}

export interface UpdateSeelenbildZahlenRequest {
    id: number;
    seelenbildZahlen: SeelenbildZahlen;
}

/**
 * 
 */
export class SeelenbildZahlenControllerApi extends runtime.BaseAPI {

    /**
     */
    async createSeelenbildZahlenRaw(requestParameters: CreateSeelenbildZahlenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SeelenbildZahlen>> {
        if (requestParameters['seelenbildZahlen'] == null) {
            throw new runtime.RequiredError(
                'seelenbildZahlen',
                'Required parameter "seelenbildZahlen" was null or undefined when calling createSeelenbildZahlen().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/seelenbild_zahlen`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SeelenbildZahlenToJSON(requestParameters['seelenbildZahlen']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SeelenbildZahlenFromJSON(jsonValue));
    }

    /**
     */
    async createSeelenbildZahlen(requestParameters: CreateSeelenbildZahlenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SeelenbildZahlen> {
        const response = await this.createSeelenbildZahlenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteSeelenbildZahlenRaw(requestParameters: DeleteSeelenbildZahlenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteSeelenbildZahlen().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/seelenbild_zahlen/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteSeelenbildZahlen(requestParameters: DeleteSeelenbildZahlenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSeelenbildZahlenRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllSeelenbildZahlenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SeelenbildZahlen>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/seelenbild_zahlen`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SeelenbildZahlenFromJSON));
    }

    /**
     */
    async getAllSeelenbildZahlen(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SeelenbildZahlen>> {
        const response = await this.getAllSeelenbildZahlenRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getSeelenbildZahlenByIdRaw(requestParameters: GetSeelenbildZahlenByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SeelenbildZahlen>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getSeelenbildZahlenById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/seelenbild_zahlen/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SeelenbildZahlenFromJSON(jsonValue));
    }

    /**
     */
    async getSeelenbildZahlenById(requestParameters: GetSeelenbildZahlenByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SeelenbildZahlen> {
        const response = await this.getSeelenbildZahlenByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateSeelenbildZahlenRaw(requestParameters: UpdateSeelenbildZahlenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SeelenbildZahlen>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateSeelenbildZahlen().'
            );
        }

        if (requestParameters['seelenbildZahlen'] == null) {
            throw new runtime.RequiredError(
                'seelenbildZahlen',
                'Required parameter "seelenbildZahlen" was null or undefined when calling updateSeelenbildZahlen().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/seelenbild_zahlen/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SeelenbildZahlenToJSON(requestParameters['seelenbildZahlen']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SeelenbildZahlenFromJSON(jsonValue));
    }

    /**
     */
    async updateSeelenbildZahlen(requestParameters: UpdateSeelenbildZahlenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SeelenbildZahlen> {
        const response = await this.updateSeelenbildZahlenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
