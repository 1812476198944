/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LegendEntryColor } from './LegendEntryColor';
import {
    LegendEntryColorFromJSON,
    LegendEntryColorFromJSONTyped,
    LegendEntryColorToJSON,
} from './LegendEntryColor';

/**
 * 
 * @export
 * @interface LegendEntry
 */
export interface LegendEntry {
    /**
     * 
     * @type {number}
     * @memberof LegendEntry
     */
    number?: number;
    /**
     * 
     * @type {LegendEntryColor}
     * @memberof LegendEntry
     */
    color?: LegendEntryColor;
}

/**
 * Check if a given object implements the LegendEntry interface.
 */
export function instanceOfLegendEntry(value: object): value is LegendEntry {
    return true;
}

export function LegendEntryFromJSON(json: any): LegendEntry {
    return LegendEntryFromJSONTyped(json, false);
}

export function LegendEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegendEntry {
    if (json == null) {
        return json;
    }
    return {
        
        'number': json['number'] == null ? undefined : json['number'],
        'color': json['color'] == null ? undefined : LegendEntryColorFromJSON(json['color']),
    };
}

export function LegendEntryToJSON(value?: LegendEntry | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'number': value['number'],
        'color': LegendEntryColorToJSON(value['color']),
    };
}

