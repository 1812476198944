import { Switch } from "@nextui-org/react";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { themeState } from "@states/ThemeState";
import { darkTheme } from "@themes/dark";
import { lightTheme } from "@themes/light";
import { useHookstate } from "@hookstate/core";
import { DARK_THEME_NAME, LIGHT_THEME_NAME } from "@utils/constants";

export const ThemeSwitch = () => {
    const state = useHookstate(themeState);
    const isDarkMode = state.get().name === DARK_THEME_NAME;

    const toggleThemeState = () => {
        if (state.get().name === LIGHT_THEME_NAME) {
            state.set(darkTheme);
        } else {
            state.set(lightTheme);
        }
    };

    return (
        <Switch
            checked={isDarkMode}
            size="xl"
            iconOn={<RiMoonFill />}
            iconOff={<RiSunFill />}
            onChange={toggleThemeState}
        />
    );
};
