/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UnsichtbarerLebensbaum,
} from '../models/index';
import {
    UnsichtbarerLebensbaumFromJSON,
    UnsichtbarerLebensbaumToJSON,
} from '../models/index';

export interface CreateUnsichtbarerLebensbaumRequest {
    unsichtbarerLebensbaum: UnsichtbarerLebensbaum;
}

export interface DeleteUnsichtbarerLebensbaumRequest {
    id: number;
}

export interface GetUnsichtbarerLebensbaumByIdRequest {
    id: number;
}

export interface UpdateUnsichtbarerLebensbaumRequest {
    id: number;
    unsichtbarerLebensbaum: UnsichtbarerLebensbaum;
}

/**
 * 
 */
export class UnsichtbarerLebensbaumControllerApi extends runtime.BaseAPI {

    /**
     */
    async createUnsichtbarerLebensbaumRaw(requestParameters: CreateUnsichtbarerLebensbaumRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UnsichtbarerLebensbaum>> {
        if (requestParameters['unsichtbarerLebensbaum'] == null) {
            throw new runtime.RequiredError(
                'unsichtbarerLebensbaum',
                'Required parameter "unsichtbarerLebensbaum" was null or undefined when calling createUnsichtbarerLebensbaum().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/unsichtbarer_lebensbaum`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnsichtbarerLebensbaumToJSON(requestParameters['unsichtbarerLebensbaum']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UnsichtbarerLebensbaumFromJSON(jsonValue));
    }

    /**
     */
    async createUnsichtbarerLebensbaum(requestParameters: CreateUnsichtbarerLebensbaumRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UnsichtbarerLebensbaum> {
        const response = await this.createUnsichtbarerLebensbaumRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteUnsichtbarerLebensbaumRaw(requestParameters: DeleteUnsichtbarerLebensbaumRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteUnsichtbarerLebensbaum().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/unsichtbarer_lebensbaum/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUnsichtbarerLebensbaum(requestParameters: DeleteUnsichtbarerLebensbaumRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUnsichtbarerLebensbaumRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllUnsichtbarerLebensbaumRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UnsichtbarerLebensbaum>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/unsichtbarer_lebensbaum`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UnsichtbarerLebensbaumFromJSON));
    }

    /**
     */
    async getAllUnsichtbarerLebensbaum(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UnsichtbarerLebensbaum>> {
        const response = await this.getAllUnsichtbarerLebensbaumRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUnsichtbarerLebensbaumByIdRaw(requestParameters: GetUnsichtbarerLebensbaumByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UnsichtbarerLebensbaum>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getUnsichtbarerLebensbaumById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/unsichtbarer_lebensbaum/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UnsichtbarerLebensbaumFromJSON(jsonValue));
    }

    /**
     */
    async getUnsichtbarerLebensbaumById(requestParameters: GetUnsichtbarerLebensbaumByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UnsichtbarerLebensbaum> {
        const response = await this.getUnsichtbarerLebensbaumByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUnsichtbarerLebensbaumRaw(requestParameters: UpdateUnsichtbarerLebensbaumRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UnsichtbarerLebensbaum>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateUnsichtbarerLebensbaum().'
            );
        }

        if (requestParameters['unsichtbarerLebensbaum'] == null) {
            throw new runtime.RequiredError(
                'unsichtbarerLebensbaum',
                'Required parameter "unsichtbarerLebensbaum" was null or undefined when calling updateUnsichtbarerLebensbaum().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/unsichtbarer_lebensbaum/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UnsichtbarerLebensbaumToJSON(requestParameters['unsichtbarerLebensbaum']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UnsichtbarerLebensbaumFromJSON(jsonValue));
    }

    /**
     */
    async updateUnsichtbarerLebensbaum(requestParameters: UpdateUnsichtbarerLebensbaumRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UnsichtbarerLebensbaum> {
        const response = await this.updateUnsichtbarerLebensbaumRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
