/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LegendEntry } from './LegendEntry';
import {
    LegendEntryFromJSON,
    LegendEntryFromJSONTyped,
    LegendEntryToJSON,
} from './LegendEntry';

/**
 * 
 * @export
 * @interface LebensbaumDTO
 */
export interface LebensbaumDTO {
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof LebensbaumDTO
     */
    lineCircles?: Array<Array<number>>;
    /**
     * 
     * @type {Array<LegendEntry>}
     * @memberof LebensbaumDTO
     */
    legendEntries?: Array<LegendEntry>;
}

/**
 * Check if a given object implements the LebensbaumDTO interface.
 */
export function instanceOfLebensbaumDTO(value: object): value is LebensbaumDTO {
    return true;
}

export function LebensbaumDTOFromJSON(json: any): LebensbaumDTO {
    return LebensbaumDTOFromJSONTyped(json, false);
}

export function LebensbaumDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LebensbaumDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'lineCircles': json['lineCircles'] == null ? undefined : json['lineCircles'],
        'legendEntries': json['legendEntries'] == null ? undefined : ((json['legendEntries'] as Array<any>).map(LegendEntryFromJSON)),
    };
}

export function LebensbaumDTOToJSON(value?: LebensbaumDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'lineCircles': value['lineCircles'],
        'legendEntries': value['legendEntries'] == null ? undefined : ((value['legendEntries'] as Array<any>).map(LegendEntryToJSON)),
    };
}

