/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UnsichtbarerLebensbaumSephiroth,
} from '../models/index';
import {
    UnsichtbarerLebensbaumSephirothFromJSON,
    UnsichtbarerLebensbaumSephirothToJSON,
} from '../models/index';

export interface CreateUnsichtbarerLebensbaumSephirothRequest {
    unsichtbarerLebensbaumSephiroth: UnsichtbarerLebensbaumSephiroth;
}

export interface DeleteUnsichtbarerLebensbaumSephirothRequest {
    id: number;
}

export interface GetUnsichtbarerLebensbaumSephirothByIdRequest {
    id: number;
}

export interface UpdateUnsichtbarerLebensbaumSephirothRequest {
    id: number;
    unsichtbarerLebensbaumSephiroth: UnsichtbarerLebensbaumSephiroth;
}

/**
 * 
 */
export class UnsichtbarerLebensbaumSephirothControllerApi extends runtime.BaseAPI {

    /**
     */
    async createUnsichtbarerLebensbaumSephirothRaw(requestParameters: CreateUnsichtbarerLebensbaumSephirothRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UnsichtbarerLebensbaumSephiroth>> {
        if (requestParameters['unsichtbarerLebensbaumSephiroth'] == null) {
            throw new runtime.RequiredError(
                'unsichtbarerLebensbaumSephiroth',
                'Required parameter "unsichtbarerLebensbaumSephiroth" was null or undefined when calling createUnsichtbarerLebensbaumSephiroth().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/unsichtbarer_lebensbaum_sephiroth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnsichtbarerLebensbaumSephirothToJSON(requestParameters['unsichtbarerLebensbaumSephiroth']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UnsichtbarerLebensbaumSephirothFromJSON(jsonValue));
    }

    /**
     */
    async createUnsichtbarerLebensbaumSephiroth(requestParameters: CreateUnsichtbarerLebensbaumSephirothRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UnsichtbarerLebensbaumSephiroth> {
        const response = await this.createUnsichtbarerLebensbaumSephirothRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteUnsichtbarerLebensbaumSephirothRaw(requestParameters: DeleteUnsichtbarerLebensbaumSephirothRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteUnsichtbarerLebensbaumSephiroth().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/unsichtbarer_lebensbaum_sephiroth/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUnsichtbarerLebensbaumSephiroth(requestParameters: DeleteUnsichtbarerLebensbaumSephirothRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUnsichtbarerLebensbaumSephirothRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllUnsichtbarerLebensbaumSephirothRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UnsichtbarerLebensbaumSephiroth>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/unsichtbarer_lebensbaum_sephiroth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UnsichtbarerLebensbaumSephirothFromJSON));
    }

    /**
     */
    async getAllUnsichtbarerLebensbaumSephiroth(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UnsichtbarerLebensbaumSephiroth>> {
        const response = await this.getAllUnsichtbarerLebensbaumSephirothRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUnsichtbarerLebensbaumSephirothByIdRaw(requestParameters: GetUnsichtbarerLebensbaumSephirothByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UnsichtbarerLebensbaumSephiroth>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getUnsichtbarerLebensbaumSephirothById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/unsichtbarer_lebensbaum_sephiroth/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UnsichtbarerLebensbaumSephirothFromJSON(jsonValue));
    }

    /**
     */
    async getUnsichtbarerLebensbaumSephirothById(requestParameters: GetUnsichtbarerLebensbaumSephirothByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UnsichtbarerLebensbaumSephiroth> {
        const response = await this.getUnsichtbarerLebensbaumSephirothByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUnsichtbarerLebensbaumSephirothRaw(requestParameters: UpdateUnsichtbarerLebensbaumSephirothRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UnsichtbarerLebensbaumSephiroth>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateUnsichtbarerLebensbaumSephiroth().'
            );
        }

        if (requestParameters['unsichtbarerLebensbaumSephiroth'] == null) {
            throw new runtime.RequiredError(
                'unsichtbarerLebensbaumSephiroth',
                'Required parameter "unsichtbarerLebensbaumSephiroth" was null or undefined when calling updateUnsichtbarerLebensbaumSephiroth().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/unsichtbarer_lebensbaum_sephiroth/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UnsichtbarerLebensbaumSephirothToJSON(requestParameters['unsichtbarerLebensbaumSephiroth']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UnsichtbarerLebensbaumSephirothFromJSON(jsonValue));
    }

    /**
     */
    async updateUnsichtbarerLebensbaumSephiroth(requestParameters: UpdateUnsichtbarerLebensbaumSephirothRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UnsichtbarerLebensbaumSephiroth> {
        const response = await this.updateUnsichtbarerLebensbaumSephirothRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
