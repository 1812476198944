/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SolareZahlen,
} from '../models/index';
import {
    SolareZahlenFromJSON,
    SolareZahlenToJSON,
} from '../models/index';

export interface CreateSolareZahlenRequest {
    solareZahlen: SolareZahlen;
}

export interface DeleteSolareZahlenRequest {
    id: number;
}

export interface GetSolareZahlenByIdRequest {
    id: number;
}

export interface UpdateSolareZahlenRequest {
    id: number;
    solareZahlen: SolareZahlen;
}

/**
 * 
 */
export class SolareZahlenControllerApi extends runtime.BaseAPI {

    /**
     */
    async createSolareZahlenRaw(requestParameters: CreateSolareZahlenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SolareZahlen>> {
        if (requestParameters['solareZahlen'] == null) {
            throw new runtime.RequiredError(
                'solareZahlen',
                'Required parameter "solareZahlen" was null or undefined when calling createSolareZahlen().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/solarezahlen`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SolareZahlenToJSON(requestParameters['solareZahlen']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SolareZahlenFromJSON(jsonValue));
    }

    /**
     */
    async createSolareZahlen(requestParameters: CreateSolareZahlenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SolareZahlen> {
        const response = await this.createSolareZahlenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteSolareZahlenRaw(requestParameters: DeleteSolareZahlenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteSolareZahlen().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/solarezahlen/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteSolareZahlen(requestParameters: DeleteSolareZahlenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSolareZahlenRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllSolareZahlenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SolareZahlen>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/solarezahlen`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SolareZahlenFromJSON));
    }

    /**
     */
    async getAllSolareZahlen(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SolareZahlen>> {
        const response = await this.getAllSolareZahlenRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getSolareZahlenByIdRaw(requestParameters: GetSolareZahlenByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SolareZahlen>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getSolareZahlenById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/solarezahlen/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SolareZahlenFromJSON(jsonValue));
    }

    /**
     */
    async getSolareZahlenById(requestParameters: GetSolareZahlenByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SolareZahlen> {
        const response = await this.getSolareZahlenByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateSolareZahlenRaw(requestParameters: UpdateSolareZahlenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SolareZahlen>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateSolareZahlen().'
            );
        }

        if (requestParameters['solareZahlen'] == null) {
            throw new runtime.RequiredError(
                'solareZahlen',
                'Required parameter "solareZahlen" was null or undefined when calling updateSolareZahlen().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/solarezahlen/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SolareZahlenToJSON(requestParameters['solareZahlen']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SolareZahlenFromJSON(jsonValue));
    }

    /**
     */
    async updateSolareZahlen(requestParameters: UpdateSolareZahlenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SolareZahlen> {
        const response = await this.updateSolareZahlenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
