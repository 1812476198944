/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Energiebild,
} from '../models/index';
import {
    EnergiebildFromJSON,
    EnergiebildToJSON,
} from '../models/index';

export interface CreateEnergiebildRequest {
    energiebild: Energiebild;
}

export interface DeleteEnergiebildRequest {
    id: number;
}

export interface GetEnergiebildByIdRequest {
    id: number;
}

export interface UpdateEnergiebildRequest {
    id: number;
    energiebild: Energiebild;
}

/**
 * 
 */
export class EnergiebildControllerApi extends runtime.BaseAPI {

    /**
     */
    async createEnergiebildRaw(requestParameters: CreateEnergiebildRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Energiebild>> {
        if (requestParameters['energiebild'] == null) {
            throw new runtime.RequiredError(
                'energiebild',
                'Required parameter "energiebild" was null or undefined when calling createEnergiebild().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/energiebild`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EnergiebildToJSON(requestParameters['energiebild']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergiebildFromJSON(jsonValue));
    }

    /**
     */
    async createEnergiebild(requestParameters: CreateEnergiebildRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Energiebild> {
        const response = await this.createEnergiebildRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteEnergiebildRaw(requestParameters: DeleteEnergiebildRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteEnergiebild().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/energiebild/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteEnergiebild(requestParameters: DeleteEnergiebildRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEnergiebildRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllEnergiebilderRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Energiebild>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/energiebild`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EnergiebildFromJSON));
    }

    /**
     */
    async getAllEnergiebilder(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Energiebild>> {
        const response = await this.getAllEnergiebilderRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getEnergiebildByIdRaw(requestParameters: GetEnergiebildByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Energiebild>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getEnergiebildById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/energiebild/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergiebildFromJSON(jsonValue));
    }

    /**
     */
    async getEnergiebildById(requestParameters: GetEnergiebildByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Energiebild> {
        const response = await this.getEnergiebildByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateEnergiebildRaw(requestParameters: UpdateEnergiebildRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Energiebild>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateEnergiebild().'
            );
        }

        if (requestParameters['energiebild'] == null) {
            throw new runtime.RequiredError(
                'energiebild',
                'Required parameter "energiebild" was null or undefined when calling updateEnergiebild().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/energiebild/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EnergiebildToJSON(requestParameters['energiebild']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergiebildFromJSON(jsonValue));
    }

    /**
     */
    async updateEnergiebild(requestParameters: UpdateEnergiebildRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Energiebild> {
        const response = await this.updateEnergiebildRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
