/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SolareZahlen
 */
export interface SolareZahlen {
    /**
     * 
     * @type {number}
     * @memberof SolareZahlen
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SolareZahlen
     */
    allgemein?: string;
    /**
     * 
     * @type {string}
     * @memberof SolareZahlen
     */
    lebensmotto?: string;
    /**
     * 
     * @type {string}
     * @memberof SolareZahlen
     */
    partnerschaftszahl?: string;
    /**
     * 
     * @type {string}
     * @memberof SolareZahlen
     */
    talent?: string;
    /**
     * 
     * @type {string}
     * @memberof SolareZahlen
     */
    titel?: string;
}

/**
 * Check if a given object implements the SolareZahlen interface.
 */
export function instanceOfSolareZahlen(value: object): value is SolareZahlen {
    return true;
}

export function SolareZahlenFromJSON(json: any): SolareZahlen {
    return SolareZahlenFromJSONTyped(json, false);
}

export function SolareZahlenFromJSONTyped(json: any, ignoreDiscriminator: boolean): SolareZahlen {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'allgemein': json['allgemein'] == null ? undefined : json['allgemein'],
        'lebensmotto': json['lebensmotto'] == null ? undefined : json['lebensmotto'],
        'partnerschaftszahl': json['partnerschaftszahl'] == null ? undefined : json['partnerschaftszahl'],
        'talent': json['talent'] == null ? undefined : json['talent'],
        'titel': json['titel'] == null ? undefined : json['titel'],
    };
}

export function SolareZahlenToJSON(value?: SolareZahlen | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'allgemein': value['allgemein'],
        'lebensmotto': value['lebensmotto'],
        'partnerschaftszahl': value['partnerschaftszahl'],
        'talent': value['talent'],
        'titel': value['titel'],
    };
}

