import React, { useState, useEffect, ChangeEvent } from "react";
import { Table, Text, Input, Button, FormElement, useModal, Spacer, Card } from "@nextui-org/react";
import * as runtime from "../../../client/runtime";
import { LernaufgabenControllerApi, Role } from "../../../client";
import { BaseAPI } from "../../../client/runtime";
import { AdminTableUpdateModal } from "@components/routes/comps/AdminTableUpdateModal";
import { AdminRegisterUserForm } from "@components/routes/comps/AdminRegisterUserForm";
interface Column {
  header: string;
  accessor: string;
}

interface GenericTableProps {
  controllerName: runtime.BaseAPI;
  getMethod: string;
  updateMethod: string;
  updateDataField: string;
}

export const renderValue = (data: any) => {
  if (typeof data === 'object' && data !== null) {
    if (Array.isArray(data)) {
      // Handle arrays by joining their values with commas
      return data.join(', ');
    } else if (data instanceof Set && data.size > 0) {
      // Handle sets by converting them to a comma-separated string
      return Array.from(data.values()).map(role => role.name).join(", "); // TODO current hotfix for displaying Set<Role>
      //return Array.from(data.values()).join(', ');
    } else if (data instanceof Date) {
      // Format dates in a desired way
      return data.toLocaleString(); // Example: "7/14/2024, 3:00:00 PM"
    } else if (data instanceof Map && data.size > 0) {
      // Convert Map<Key, Value> to string representation
      const mapEntries = Array.from(data.entries()).map(([key, value]) => `${key}: ${value}`).join(', ');
      return mapEntries;
    } else if (typeof data === 'object') {
      // Handle plain objects by stringifying them
      return JSON.stringify(data);
    }
  }

  // Fallback to string representation if no special case matches
  return data?.toString() || '';
};

export const AdminTable: React.FC<GenericTableProps> = ({ controllerName, getMethod, updateMethod, updateDataField }) => {
  const [data, setData] = useState<any[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const apiController = controllerName;
  const { setVisible, bindings } = useModal();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await (apiController as any)[getMethod]();
        setData(response);
        if (response.length > 0) {
          const keys = Object.keys(response[0]);
          setColumns(keys.map(key => ({ header: key, accessor: key })));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [apiController, getMethod]);

  const handleSelectionChange = (selectedKeys: Set<React.Key>) => {
    if (selectedKeys.size === 0) {
      setSelectedRow(null);
    } else {
      const selectedIndex = Array.from(selectedKeys)[0] as number;
      setSelectedRow(data[selectedIndex]);
      console.log('Selected row data:', data[selectedIndex]);
      console.log("Daten kommen von:");
      console.log(controllerName);
      console.log(getMethod);
      console.log(updateMethod);
      setVisible(true);
    }
  };


  if (!data.length) return <div>Loading...</div>;

  return (
    <>
    <Table
      aria-label="Generic Data Table"
      css={{ height: 'auto', minWidth: '100%' }}
      selectionMode="single"
      onSelectionChange={(keys) => handleSelectionChange(keys as Set<React.Key>)} // trigger clicking a row
    >
      <Table.Header>
        {columns.map(col => (
          <Table.Column key={col.accessor}>{col.header}</Table.Column>
        ))}
      </Table.Header>
      <Table.Body>
        {data.map((row, rowIndex) => (
          <Table.Row key={rowIndex} data-index={rowIndex}>
            {columns.map(col => (
              <Table.Cell key={col.accessor}>
                {typeof row[col.accessor] === 'object'
                  ? renderValue(row[col.accessor])
                  : row[col.accessor]}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>

  <AdminTableUpdateModal
    bindings={bindings}
    selectedRow={selectedRow}
    onClose={() => setVisible(false)}
    controllerName={controllerName}
    updateMethod={updateMethod}
    updateDataField={updateDataField}
  />
    </>
  );
};
