import React, { useState } from 'react';
import { Grid, Input, Button } from '@nextui-org/react';
import { apiConfig } from "@api/configuration";
import { AuthControllerApi, RegisterUserRequest } from "../../../client";
import { DEFAULT_TOASTER_OPTIONS } from "@utils/constants";
import { toast } from "react-toastify"; // Passe dies entsprechend deiner Verzeichnisstruktur an

export const AdminRegisterUserForm = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState<Set<string>>(new Set());
  const api = new AuthControllerApi(apiConfig);
//ROLE_USER,
//     ROLE_MODERATOR,
//     ROLE_ADMIN
  const handleRegister = () => {
    const signUpRequest: RegisterUserRequest = {
      signUpRequest: {
        username: username,
        email: email,
        role: role,
        password: password,
      },
    };

  api.registerUser(signUpRequest).then((res) => {
    console.log(res);
    toast.success("Benutzer wurde erfolgreich registriert", DEFAULT_TOASTER_OPTIONS);
  }).catch((error) => {
    console.log(error);
    toast.error("Fehler beim Registrieren des Benutzers", DEFAULT_TOASTER_OPTIONS);
  });

    setUsername('');
    setEmail('');
    setPassword('');
    setRole(new Set());
  };

  return (
    <Grid.Container gap={2}>
      <Grid>
        <Input
          type="text"
          placeholder="Benutzername"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </Grid>
      <Grid>
        <Input
          type="email"
          placeholder="E-Mail (optional)"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Grid>
      <Grid>
        <Input
          type="text"
          placeholder="Rolle (optional, kommasepariert (ROLE_USER, ROLE_ADMIN, ROLE_MODERATOR))"
          value={Array.from(role).join(',')}
          onChange={(e) => setRole(new Set(e.target.value.split(',')))}
        />
      </Grid>
      <Grid>
        <Input
          type="password"
          placeholder="Passwort (zwischen 6 und 40 Zeichen)"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Grid>
      <Grid>
        <Button onClick={handleRegister} auto>
          Registrieren
        </Button>
      </Grid>
    </Grid.Container>
  );
};
