/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LegendEntryColorColorSpace
 */
export interface LegendEntryColorColorSpace {
    /**
     * 
     * @type {number}
     * @memberof LegendEntryColorColorSpace
     */
    type?: number;
    /**
     * 
     * @type {number}
     * @memberof LegendEntryColorColorSpace
     */
    numComponents?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LegendEntryColorColorSpace
     */
    csSRGB?: boolean;
}

/**
 * Check if a given object implements the LegendEntryColorColorSpace interface.
 */
export function instanceOfLegendEntryColorColorSpace(value: object): value is LegendEntryColorColorSpace {
    return true;
}

export function LegendEntryColorColorSpaceFromJSON(json: any): LegendEntryColorColorSpace {
    return LegendEntryColorColorSpaceFromJSONTyped(json, false);
}

export function LegendEntryColorColorSpaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegendEntryColorColorSpace {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'numComponents': json['numComponents'] == null ? undefined : json['numComponents'],
        'csSRGB': json['cs_sRGB'] == null ? undefined : json['cs_sRGB'],
    };
}

export function LegendEntryColorColorSpaceToJSON(value?: LegendEntryColorColorSpace | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'numComponents': value['numComponents'],
        'cs_sRGB': value['csSRGB'],
    };
}

