import "./comps/energiebild.css";
import { Spacer, Text, Container, Card, Grid } from "@nextui-org/react";
import { useHookstate } from "@hookstate/core";
import { calculationHistoryState } from "@states/CalculationHistoryState";
import { useEffect, useState } from "react";
import { NoDataContainer } from "@components/routes/comps/NoDataContainer";

export const Energiebild = () => {
    const calculationState = useHookstate(calculationHistoryState);
    const latestCalculationResult = calculationState.get()[calculationState.length - 1];

    const energiebild = latestCalculationResult?.energiebild;
    const [canvas, setCanvas] = useState<SVGSVGElement>(document.createElementNS("http://www.w3.org/2000/svg", "svg"));

    useEffect(() => {
        if (!energiebild) return;
        if (!energiebild.canvas) return;

        const svg = new DOMParser()
            .parseFromString(energiebild.canvas, "text/html")
            .querySelector("svg");

        if (!svg) return;

        svg.setAttribute("viewBox", "-50 50 600 600");
        setCanvas(svg);
    }, [energiebild]);

    return <Container css={{maxWidth: "1400px"}}>
        <Spacer />
        <Spacer />

        {/* Heading */}
        <Text
            h1
            size={48}
            css={{ textGradient: "45deg, $blue600 -20%, $pink600 50%", marginBottom: 0}}
            weight="bold"
        >
            Energiebild
        </Text>

        <Spacer />

        {energiebild ? (
        <Grid.Container gap={1}>
            <Grid xs={12} md={8}>
                <Card>
                    <Card.Body>
                        <div
                            className="energiebild"
                            dangerouslySetInnerHTML={{ __html: canvas.outerHTML }}
                        />
                    </Card.Body>
                </Card>
            </Grid>

            <Grid xs={12} md={4}>
                <Card>
                    <Card.Body>
                        <Text h4>Legende</Text>
                        <Spacer />
                        
                        <Text h5>Zahlen</Text>
                        <Text css={{ ml: "$2", color: "red" }}>Lernaufgabe</Text>
                        <Text css={{ ml: "$2", color: "lime" }}>Talent</Text>
                        <Text css={{ ml: "$2", color: "black" }}>Symptom</Text>
                        <Text css={{ ml: "$2", color: "rgb(0, 157, 255)" }}>Gewandelte Geburtsdaten</Text>
                        <Spacer />

                        <Text h5>Pfeile</Text>
                        <Text css={{ ml: "$2", color: "red" }}>Symptomwirkung</Text>
                        <Text css={{ ml: "$2", color: "rgb(0, 157, 255)" }}>Polare Entsprechung</Text>
                    </Card.Body>
                </Card>
            </Grid>
        </Grid.Container>
        ) : (
          <NoDataContainer width="50%" height="auto" />
        )}
    </Container>;
}