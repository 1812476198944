/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CalculateRequest,
  CalculateResponse,
  GenerateResponse,
} from '../models/index';
import {
    CalculateRequestFromJSON,
    CalculateRequestToJSON,
    CalculateResponseFromJSON,
    CalculateResponseToJSON,
    GenerateResponseFromJSON,
    GenerateResponseToJSON,
} from '../models/index';

export interface CalculateOperationRequest {
    calculateRequest: CalculateRequest;
}

export interface Calculate1Request {
    calculateRequest: CalculateRequest;
}

export interface GenerateReportRequest {
    calculateRequest: CalculateRequest;
}

/**
 * 
 */
export class CalculateControllerApi extends runtime.BaseAPI {

    /**
     */
    async calculateRaw(requestParameters: CalculateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalculateResponse>> {
        if (requestParameters['calculateRequest'] == null) {
            throw new runtime.RequiredError(
                'calculateRequest',
                'Required parameter "calculateRequest" was null or undefined when calling calculate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/calculate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CalculateRequestToJSON(requestParameters['calculateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalculateResponseFromJSON(jsonValue));
    }

    /**
     */
    async calculate(requestParameters: CalculateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalculateResponse> {
        const response = await this.calculateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async calculate1Raw(requestParameters: Calculate1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalculateResponse>> {
        if (requestParameters['calculateRequest'] == null) {
            throw new runtime.RequiredError(
                'calculateRequest',
                'Required parameter "calculateRequest" was null or undefined when calling calculate1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/calculate/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CalculateRequestToJSON(requestParameters['calculateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalculateResponseFromJSON(jsonValue));
    }

    /**
     */
    async calculate1(requestParameters: Calculate1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalculateResponse> {
        const response = await this.calculate1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async generateReportRaw(requestParameters: GenerateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenerateResponse>> {
        if (requestParameters['calculateRequest'] == null) {
            throw new runtime.RequiredError(
                'calculateRequest',
                'Required parameter "calculateRequest" was null or undefined when calling generateReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/calculate/report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CalculateRequestToJSON(requestParameters['calculateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenerateResponseFromJSON(jsonValue));
    }

    /**
     */
    async generateReport(requestParameters: GenerateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenerateResponse> {
        const response = await this.generateReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
