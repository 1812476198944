import { hookstate } from "@hookstate/core";
import { localstored } from "@hookstate/localstored";
import {
    LOCAL_STORAGE_CALCULATION_REQUEST_HISTORY_KEY
} from "@utils/constants";
import { CalculateOperationRequest } from "../client";

export const calculationRequestHistoryState = hookstate(
    [] as CalculateOperationRequest[],
    localstored({ key: LOCAL_STORAGE_CALCULATION_REQUEST_HISTORY_KEY })
);
