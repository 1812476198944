import { Container, Card, Text, Spacer } from "@nextui-org/react";
import { useHookstate } from "@hookstate/core";
import { calculationHistoryState } from "@states/CalculationHistoryState";
import { useEffect, useState } from "react";
import { WiederbegegnungZelle } from "client";
import { TableCard } from "@components/routes/comps/TableCard";
import { NoDataContainer } from "@components/routes/comps/NoDataContainer";

export const KarmischeWiederbegegnung = () => {
  const [cells, setCells] = useState<WiederbegegnungZelle[][]>(
    Array(6).fill([]).map(() =>
      Array(4).fill({
        middleX: 0,
        middleY: 0,
        content: "",
      })
    )
  );

  const calculationState = useHookstate(calculationHistoryState);
  const calculationHistory = calculationState.get();
  const latestCalculationResult = calculationHistory.length > 0 ? calculationHistory[calculationHistory.length - 1] : null;
  const content = latestCalculationResult?.wiederbegegnung;

  useEffect(() => {
    if (!content || !content.cells) return;

    const wiederbegegnungCells: WiederbegegnungZelle[][] = Array(6)
      .fill([])
      .map(() =>
        Array(4).fill({
          middleX: 0,
          middleY: 0,
          content: "",
        })
      );

    for (let i = 0; i < content.cells.length; i++) {
      const row = content.cells[i];
      for (let j = 0; j < row.length; j++) {
        const cell = row[j];
        wiederbegegnungCells[i][j] = cell;
      }
    }
    setCells(wiederbegegnungCells);
  }, [content]);

  return (
    <Container css={{maxWidth: "1400px"}}>
      <Spacer y={2} />

      {/* Heading */}
      <Text
        h1
        size={48}
        css={{ textGradient: "45deg, $blue600 -20%, $pink600 50%", marginBottom: 0 }}
        weight="bold"
      >
        Karmische
      </Text>
      <Text
        h1
        size={48}
        css={{ textGradient: "45deg, $blue600 -20%, $pink600 50%", marginBottom: 0 }}
        weight="bold"
      >
        Wiederbegegnung
      </Text>

      <Spacer y={1} />

      {content ? (
      <Card>
        <Card.Body>
          <Spacer y={2} />

          <div style={{ overflowX: "auto", maxWidth: "100%", marginBottom: "20px", display: "flex", justifyContent: "center" }}>
            <table style={{ width: "80%", maxWidth: "1200px", borderCollapse: "collapse" }}>
              <thead>
              <tr>
                <th style={{ width: "20%" }}></th> {/* Festlegen der Breite für die erste Spalte */}
                <th>Geist</th>
                <th>Seele</th>
                <th>Körper</th>
                <th>Unbezahlte Rechnungen</th>
                <th style={{ width: "20%" }}></th>
              </tr>
              </thead>
              <tbody>
              {cells.map((row, rowIndex) => (
                <tr key={`row-${rowIndex}`}>
                  <td>
                    <Text css={{ textAlign: "right", marginRight: "12px", margin: "0", maxWidth: "100%" }}>
                      {rowIndex === 0 ? "Geist" :
                        rowIndex === 1 ? "Seele" :
                          rowIndex === 2 ? "Körper" :
                            rowIndex === 3 ? "Archaisches Potential" :
                              rowIndex === 4 ? "" :
                                rowIndex === 5 ? "" : ""
                      }
                    </Text>
                  </td>
                  {row.map((cell, colIndex) => (
                    <td key={`cell-${rowIndex}-${colIndex}`} style={{ width: `${80 / 4}%`, padding: "0px", margin: "0" }}>
                      {cell && (
                        <TableCard centerText={cell.content} variant={
                          rowIndex === 0 && colIndex === 0 ? "top-left" :
                            rowIndex === 0 && colIndex === 3 ? "top-right" :
                              rowIndex === 3 && colIndex === 0 ? "bottom-left" :
                                rowIndex === 4 && colIndex === 3 ? "default" :
                                  rowIndex === 5 && colIndex === 3 ? "bottom" :
                                    "default"
                        } />
                      )}
                    </td>
                  ))}
                  <td style={{ width: "20%" }}></td> {/* Festlegen der Breite für die letzte Spalte */}
                </tr>
              ))}
              </tbody>
            </table>
          </div>

          <Spacer y={2} />
        </Card.Body>
      </Card>
      ) : (
      <NoDataContainer width="50%" height="auto" />
      )}


    </Container>
  );
};
