/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SolareZahl
 */
export interface SolareZahl {
    /**
     * 
     * @type {string}
     * @memberof SolareZahl
     */
    type?: SolareZahlTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SolareZahl
     */
    staticNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof SolareZahl
     */
    value?: number;
}


/**
 * @export
 */
export const SolareZahlTypeEnum = {
    Lernaufgabe: 'LERNAUFGABE',
    Talent: 'TALENT',
    Partnerschaftszahl: 'PARTNERSCHAFTSZAHL',
    Lebensmotto: 'LEBENSMOTTO',
    Allgemein: 'ALLGEMEIN',
    Titel: 'TITEL',
    PentagrammRotAnBlau: 'PENTAGRAMM_ROT_AN_BLAU',
    PentagrammRotAnSchwarz: 'PENTAGRAMM_ROT_AN_SCHWARZ',
    SeelenbildRotAnSchwarz: 'SEELENBILD_ROT_AN_SCHWARZ',
    SeelenbildGruenAnSchwarz: 'SEELENBILD_GRUEN_AN_SCHWARZ',
    Undefined: 'UNDEFINED'
} as const;
export type SolareZahlTypeEnum = typeof SolareZahlTypeEnum[keyof typeof SolareZahlTypeEnum];


/**
 * Check if a given object implements the SolareZahl interface.
 */
export function instanceOfSolareZahl(value: object): value is SolareZahl {
    return true;
}

export function SolareZahlFromJSON(json: any): SolareZahl {
    return SolareZahlFromJSONTyped(json, false);
}

export function SolareZahlFromJSONTyped(json: any, ignoreDiscriminator: boolean): SolareZahl {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'staticNumber': json['staticNumber'] == null ? undefined : json['staticNumber'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function SolareZahlToJSON(value?: SolareZahl | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'staticNumber': value['staticNumber'],
        'value': value['value'],
    };
}

