import { Card, Grid, Spacer, Text} from "@nextui-org/react";
import { AdminTable } from "@components/routes/comps/AdminTable";
import { controllerList } from "@api/configuration";
import { AdminRegisterUserForm } from "@components/routes/comps/AdminRegisterUserForm";
import { AdminDeleteUserForm } from "@components/routes/comps/AdminDeleteUserForm";

export const Admin = () => {

    return (
      <>
        <Spacer />
        <Spacer />

        {/* Heading */}
        <Text
          h1
          size={48}
          css={{ textGradient: "45deg, $blue600 -20%, $pink600 50%", marginBottom: 0, marginLeft: 15 }}
          weight="bold"
        >
          Adminbereich
        </Text>

        <Spacer y={1}></Spacer>

        <Grid.Container gap={2}>
            <Grid xs={12} md={12} lg={6}>
                <Card>
                    <Card.Header>
                        <Text h3>Benutzer Registrieren</Text>
                    </Card.Header>
                    <Card.Body>
                        <AdminRegisterUserForm />
                    </Card.Body>
                </Card>
            </Grid>

            <Grid xs={12} md={12} lg={6}>
                <Card>
                    <Card.Header>
                        <Text h3>Benutzer Löschen</Text>
                    </Card.Header>
                    <Card.Body>
                        <AdminDeleteUserForm />
                    </Card.Body>
                </Card>
            </Grid>

            <Spacer />
          {controllerList.map((controller, index) => (
            <Grid xs={12} md={12} key={index}>
              <Card>
                <Card.Body>
                  <Text h4>{controller.name}</Text>
                  <AdminTable
                    controllerName={controller.apiController}
                    getMethod={controller.getMethod}
                    updateMethod={controller.updateMethod}
                    updateDataField={controller.updateDataField}
                  />
                </Card.Body>
              </Card>
            </Grid>
          ))}

        </Grid.Container>

        <Spacer />
      </>
    );
}
