/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EmotionalerSpiegel,
} from '../models/index';
import {
    EmotionalerSpiegelFromJSON,
    EmotionalerSpiegelToJSON,
} from '../models/index';

export interface CreateEmotionalerSpiegelRequest {
    emotionalerSpiegel: EmotionalerSpiegel;
}

export interface DeleteEmotionalerSpiegelRequest {
    id: number;
}

export interface GetEmotionalerSpiegelByIdRequest {
    id: number;
}

export interface UpdateEmotionalerSpiegelRequest {
    id: number;
    emotionalerSpiegel: EmotionalerSpiegel;
}

/**
 * 
 */
export class EmotionalerSpiegelControllerApi extends runtime.BaseAPI {

    /**
     */
    async createEmotionalerSpiegelRaw(requestParameters: CreateEmotionalerSpiegelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmotionalerSpiegel>> {
        if (requestParameters['emotionalerSpiegel'] == null) {
            throw new runtime.RequiredError(
                'emotionalerSpiegel',
                'Required parameter "emotionalerSpiegel" was null or undefined when calling createEmotionalerSpiegel().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/emotionaler_spiegel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmotionalerSpiegelToJSON(requestParameters['emotionalerSpiegel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmotionalerSpiegelFromJSON(jsonValue));
    }

    /**
     */
    async createEmotionalerSpiegel(requestParameters: CreateEmotionalerSpiegelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmotionalerSpiegel> {
        const response = await this.createEmotionalerSpiegelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteEmotionalerSpiegelRaw(requestParameters: DeleteEmotionalerSpiegelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteEmotionalerSpiegel().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/emotionaler_spiegel/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteEmotionalerSpiegel(requestParameters: DeleteEmotionalerSpiegelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEmotionalerSpiegelRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllEmotionalerSpiegelRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EmotionalerSpiegel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/emotionaler_spiegel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmotionalerSpiegelFromJSON));
    }

    /**
     */
    async getAllEmotionalerSpiegel(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EmotionalerSpiegel>> {
        const response = await this.getAllEmotionalerSpiegelRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getEmotionalerSpiegelByIdRaw(requestParameters: GetEmotionalerSpiegelByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmotionalerSpiegel>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getEmotionalerSpiegelById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/emotionaler_spiegel/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmotionalerSpiegelFromJSON(jsonValue));
    }

    /**
     */
    async getEmotionalerSpiegelById(requestParameters: GetEmotionalerSpiegelByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmotionalerSpiegel> {
        const response = await this.getEmotionalerSpiegelByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateEmotionalerSpiegelRaw(requestParameters: UpdateEmotionalerSpiegelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmotionalerSpiegel>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateEmotionalerSpiegel().'
            );
        }

        if (requestParameters['emotionalerSpiegel'] == null) {
            throw new runtime.RequiredError(
                'emotionalerSpiegel',
                'Required parameter "emotionalerSpiegel" was null or undefined when calling updateEmotionalerSpiegel().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/emotionaler_spiegel/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EmotionalerSpiegelToJSON(requestParameters['emotionalerSpiegel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmotionalerSpiegelFromJSON(jsonValue));
    }

    /**
     */
    async updateEmotionalerSpiegel(requestParameters: UpdateEmotionalerSpiegelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmotionalerSpiegel> {
        const response = await this.updateEmotionalerSpiegelRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
