import { Col, Card, Container, Row, Text, Spacer } from "@nextui-org/react";
import { TableCard } from "@components/routes/comps/TableCard";
import { useHookstate } from "@hookstate/core";
import { calculationHistoryState } from "@states/CalculationHistoryState";
import { useEffect, useState } from "react";
import { SeelenbildZelle } from "client";
import { NoDataContainer } from "@components/routes/comps/NoDataContainer";

export const Seelenbild = () => {
    const [cellSortedContent, setCellSortedContent] = useState<SeelenbildZelle[][]>(
        Array(5).fill([]).map(() => Array(4).fill({
            row: 0,
            column: 0,
            number: 0,
            superscriptNumber: 0,
            dynamicNumber: 0,
            numberOccurrence: 0,
            superscriptNumberOccurrence: 0,
            labelText: "",
            cellContent: "",
        }))
    );

    const calculationState = useHookstate(calculationHistoryState);
    const latestCalculationResult = calculationState.get()[calculationState.length - 1];
    const cellContent = latestCalculationResult?.seelenbild?.cellContent;

    useEffect(() => {
        if (!cellContent) return;

        let rows = 5;
        let columns = 4;
        const content: SeelenbildZelle[][] = Array(rows).fill([]).map(() => Array(columns).fill({}));
        for (let i = 0; i < cellContent.length; i++) {
            const cell = cellContent[i];
            const row = cell.row;
            const column = cell.column;
            if (row === undefined || column === undefined)
                continue;

            content[row][column] = cell;
        }

        setCellSortedContent(content);
    }, [cellContent]);

    const getTableCard = (row: number, column: number, variant: "top-left" | "top-right" | "bottom-left" | "bottom-right" | "default" | "placeholder" | "bottom") => {
        const cell = cellSortedContent[row][column];
        return (
            <TableCard
                centerText={cell.numberOccurrence === 1 || cell.numberOccurrence === 0
                    ? Number(cell.number).toString()
                    : Number(cell.number).toString() + cell.number
                }
                powerText={cell.superscriptNumberOccurrence === 1
                    ? Number(cell.superscriptNumber).toString()
                    : cell.superscriptNumberOccurrence === 2
                        ? Number(cell.superscriptNumber).toString() + cell.superscriptNumber
                        : cell.superscriptNumberOccurrence === 0
                            ? ""
                            : ""
                }
                subscriptText={Number(cell.dynamicNumber).toString()}
                variant={variant}
            />
        );
    }

    return (
        <Container css={{maxWidth: "1400px"}}>
            <Spacer />
            <Spacer />

            {/* Heading */}
            <Text
                h1
                size={48}
                css={{ textGradient: "45deg, $blue600 -20%, $pink600 50%", marginBottom: 0}}
                weight="bold">
                Seelenbild
            </Text>

            <Spacer y={1}></Spacer>

            {cellContent ? (
              <>
            <Card>
                <Card.Body>
                    <Spacer y={2}/>
                    <Container gap={0}>
                    <Row gap={0}>
                        <Col css={{display: "flex", alignSelf: "center", justifyContent: "end"}}>
                            <Text>{cellSortedContent[0][0].labelText}</Text>
                        </Col>
                        <Col css={{ display: "flex", justifyContent: "center" }}>
                            <Text>{cellSortedContent[0][1].labelText}</Text>
                        </Col>
                        <Col css={{ display: "flex", justifyContent: "center" }}>
                            <Text>{cellSortedContent[0][2].labelText}</Text>
                        </Col>
                        <Col css={{ display: "flex", justifyContent: "center" }}>
                            <Text>{cellSortedContent[0][3].labelText}</Text>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row gap={0}>
                        <Col css={{display: "flex", alignSelf: "center", justifyContent: "end"}}>
                            <Text css={{ textAlign: "right", marginRight: "12px"}}>Geist</Text>
                        </Col>
                        <Col>
                            {getTableCard(1, 1, "top-left")}
                        </Col>
                        <Col>
                            {getTableCard(1, 2, "default")}
                        </Col>
                        <Col>
                            {getTableCard(1, 3, "top-right")}
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row gap={0}>
                        <Col css={{display: "flex", alignSelf: "center", justifyContent: "end"}}>
                            <Text css={{ textAlign: "right", marginRight: "12px"}}>Seele</Text>
                        </Col>
                        <Col>
                            {getTableCard(2, 1, "default")}
                        </Col>
                        <Col>
                            {getTableCard(2, 2, "default")} 
                        </Col>
                        <Col>
                            {getTableCard(2, 3, "default")}
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row gap={0}>
                        <Col css={{display: "flex", alignSelf: "center", justifyContent: "end"}}>
                            <Text css={{ textAlign: "right", marginRight: "12px"}}>Körper</Text>
                        </Col>
                        <Col>
                            {getTableCard(3, 1, "default")}
                        </Col>
                        <Col>
                            {getTableCard(3, 2, "default")}
                        </Col>
                        <Col>
                            {getTableCard(3, 3, "bottom-right")}
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row gap={0}>
                        <Col css={{display: "flex", alignSelf: "center", justifyContent: "end"}}>
                            <Text css={{ textAlign: "right", marginRight: "12px"}}>Wandlung</Text>
                        </Col>
                        <Col>
                            {getTableCard(4, 1, "bottom")}
                        </Col>
                        <Col css={{display: "flex", alignSelf: "center", justifyContent: "center"}}>
                            <Text>{cellSortedContent[4][2].labelText}</Text>
                        </Col>
                        <Col>

                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row gap={0}>
                        <Col>
                        </Col>
                        <Col>
                        </Col>
                        <Col>
                        </Col>
                        <Col>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row gap={0}>
                        <Col>
                        </Col>
                        <Col>
                        </Col>
                        <Col>
                        </Col>
                        <Col>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    </Container>
                    <Spacer y={2}/>
                </Card.Body>
            </Card>

            <Spacer y={2}/>

            <Card css={{ $$cardColor: '#EDF5FF' }}>
                <Card.Body>
                    <Row justify="center" align="center">
                        <Text h6 size={15} color="#11181C" css={{ m: 0 }}>
                            Alle Zahlen mit roter bzw. grüner Hochzahl bilden eine Solare Zahl. Die dazugehörigen Informationstexte können über die Info-Seite abgefragt werden. Zusätzlich sind diese auch im Bericht zu finden.
                        </Text>
                    </Row>
                </Card.Body>
            </Card>

            <Spacer y={2}/>
              </>
            ) : (
            <NoDataContainer width="50%" height="auto" />
            )}

        </Container>
    );
};
