/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EnergiebildAchsen,
} from '../models/index';
import {
    EnergiebildAchsenFromJSON,
    EnergiebildAchsenToJSON,
} from '../models/index';

export interface CreateEnergiebildAchseRequest {
    energiebildAchsen: EnergiebildAchsen;
}

export interface DeleteEnergiebildAchseRequest {
    id: number;
}

export interface GetEnergiebildAchseByIdRequest {
    id: number;
}

export interface UpdateEnergiebildAchseRequest {
    id: number;
    energiebildAchsen: EnergiebildAchsen;
}

/**
 * 
 */
export class EnergiebildAchsenControllerApi extends runtime.BaseAPI {

    /**
     */
    async createEnergiebildAchseRaw(requestParameters: CreateEnergiebildAchseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EnergiebildAchsen>> {
        if (requestParameters['energiebildAchsen'] == null) {
            throw new runtime.RequiredError(
                'energiebildAchsen',
                'Required parameter "energiebildAchsen" was null or undefined when calling createEnergiebildAchse().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/energiebild_achsen`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EnergiebildAchsenToJSON(requestParameters['energiebildAchsen']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergiebildAchsenFromJSON(jsonValue));
    }

    /**
     */
    async createEnergiebildAchse(requestParameters: CreateEnergiebildAchseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EnergiebildAchsen> {
        const response = await this.createEnergiebildAchseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteEnergiebildAchseRaw(requestParameters: DeleteEnergiebildAchseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteEnergiebildAchse().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/energiebild_achsen/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteEnergiebildAchse(requestParameters: DeleteEnergiebildAchseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEnergiebildAchseRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllEnergiebildAchsenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EnergiebildAchsen>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/energiebild_achsen`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EnergiebildAchsenFromJSON));
    }

    /**
     */
    async getAllEnergiebildAchsen(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EnergiebildAchsen>> {
        const response = await this.getAllEnergiebildAchsenRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getEnergiebildAchseByIdRaw(requestParameters: GetEnergiebildAchseByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EnergiebildAchsen>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getEnergiebildAchseById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/energiebild_achsen/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergiebildAchsenFromJSON(jsonValue));
    }

    /**
     */
    async getEnergiebildAchseById(requestParameters: GetEnergiebildAchseByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EnergiebildAchsen> {
        const response = await this.getEnergiebildAchseByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateEnergiebildAchseRaw(requestParameters: UpdateEnergiebildAchseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EnergiebildAchsen>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateEnergiebildAchse().'
            );
        }

        if (requestParameters['energiebildAchsen'] == null) {
            throw new runtime.RequiredError(
                'energiebildAchsen',
                'Required parameter "energiebildAchsen" was null or undefined when calling updateEnergiebildAchse().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/energiebild_achsen/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EnergiebildAchsenToJSON(requestParameters['energiebildAchsen']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergiebildAchsenFromJSON(jsonValue));
    }

    /**
     */
    async updateEnergiebildAchse(requestParameters: UpdateEnergiebildAchseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EnergiebildAchsen> {
        const response = await this.updateEnergiebildAchseRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
