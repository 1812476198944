/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnsichtbarerLebensbaum
 */
export interface UnsichtbarerLebensbaum {
    /**
     * 
     * @type {number}
     * @memberof UnsichtbarerLebensbaum
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UnsichtbarerLebensbaum
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof UnsichtbarerLebensbaum
     */
    titel: string;
}

/**
 * Check if a given object implements the UnsichtbarerLebensbaum interface.
 */
export function instanceOfUnsichtbarerLebensbaum(value: object): value is UnsichtbarerLebensbaum {
    if (!('text' in value) || value['text'] === undefined) return false;
    if (!('titel' in value) || value['titel'] === undefined) return false;
    return true;
}

export function UnsichtbarerLebensbaumFromJSON(json: any): UnsichtbarerLebensbaum {
    return UnsichtbarerLebensbaumFromJSONTyped(json, false);
}

export function UnsichtbarerLebensbaumFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnsichtbarerLebensbaum {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'text': json['text'],
        'titel': json['titel'],
    };
}

export function UnsichtbarerLebensbaumToJSON(value?: UnsichtbarerLebensbaum | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'text': value['text'],
        'titel': value['titel'],
    };
}

