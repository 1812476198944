import { Card, Text } from "@nextui-org/react";
import './TableCard.css';

export const TableCard = ({ centerText, powerText, subscriptText, variant }: { centerText: any; powerText?: any; subscriptText?: any; variant: "top-left" | "top-right" | "bottom-left" | "bottom-right" | "default" | "placeholder" | "bottom" }) => {
    let borderRadius = "12px 12px 12px 12px"; // Default: All corners rounded
    let cardColor = "$colors$primary";

    if (variant === "top-left") {
        borderRadius = "12px 0 0 0"; // Top left rounded
    } else if (variant === "top-right") {
        borderRadius = "0 12px 0 0"; // Top right rounded
    } else if (variant === "bottom-left") {
        borderRadius = "0 0 0 12px"; // Bottom left rounded
    } else if (variant === "bottom-right") {
        borderRadius = "0 0 12px 0"; // Bottom right rounded
    } else if (variant === "bottom") {
        borderRadius = "0 0 12px 12px"; // Bottom right rounded
    } else if (variant === "default") {
        borderRadius = "0 0 0 0"; // No rounded corners
    } else if (variant === "placeholder") {
        borderRadius = "0 0 0 0"; // No rounded corners
        cardColor = "transparent";
    }

    return (
        <Card
            className="table-card"
            css={{
                h: "$24",
                $$cardColor: cardColor,
                borderRadius: borderRadius, // Set border radius based on the variant
                display: "flex",
                justifyContent: "center", // Horizontally center the content
                alignItems: "center", // Vertically center the content
            }}
        >
            <Card.Body>
                <Text h6 size={15} color="white" css={{ mt: 0 }}>
                    {centerText}
                </Text>
                
                {
                    powerText
                        ? <Text h6 size={15} color="white"
                            css={{ mt: 0, position: "absolute", transform: "translate(20px, -20px)" }}>
                            {powerText}
                        </Text>

                        : undefined
                }

                {
                    subscriptText
                        ? <Text h6 size={15} color="white"
                            css={{ mt: 0, position: "absolute", right: "20px", bottom: "0px" }}>
                            {subscriptText}
                        </Text>

                        : undefined
                }
            </Card.Body>
        </Card>
    );
};