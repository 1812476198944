/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Input
 */
export interface Input {
    /**
     * 
     * @type {string}
     * @memberof Input
     */
    vorname?: string;
    /**
     * 
     * @type {string}
     * @memberof Input
     */
    nachname?: string;
    /**
     * 
     * @type {Date}
     * @memberof Input
     */
    geburtsdatum?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Input
     */
    berechneUnsichtbarenLebensbaum?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Input
     */
    rufname?: string;
    /**
     * 
     * @type {string}
     * @memberof Input
     */
    ehename?: string;
    /**
     * 
     * @type {string}
     * @memberof Input
     */
    activation?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Input
     */
    benutzeVornameAlsRufname?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Input
     */
    benutzeNachnameAlsEhename?: boolean;
}

/**
 * Check if a given object implements the Input interface.
 */
export function instanceOfInput(value: object): value is Input {
    return true;
}

export function InputFromJSON(json: any): Input {
    return InputFromJSONTyped(json, false);
}

export function InputFromJSONTyped(json: any, ignoreDiscriminator: boolean): Input {
    if (json == null) {
        return json;
    }
    return {
        
        'vorname': json['vorname'] == null ? undefined : json['vorname'],
        'nachname': json['nachname'] == null ? undefined : json['nachname'],
        'geburtsdatum': json['geburtsdatum'] == null ? undefined : (new Date(json['geburtsdatum'])),
        'berechneUnsichtbarenLebensbaum': json['berechneUnsichtbarenLebensbaum'] == null ? undefined : json['berechneUnsichtbarenLebensbaum'],
        'rufname': json['rufname'] == null ? undefined : json['rufname'],
        'ehename': json['ehename'] == null ? undefined : json['ehename'],
        'activation': json['activation'] == null ? undefined : json['activation'],
        'benutzeVornameAlsRufname': json['benutzeVornameAlsRufname'] == null ? undefined : json['benutzeVornameAlsRufname'],
        'benutzeNachnameAlsEhename': json['benutzeNachnameAlsEhename'] == null ? undefined : json['benutzeNachnameAlsEhename'],
    };
}

export function InputToJSON(value?: Input | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'vorname': value['vorname'],
        'nachname': value['nachname'],
        'geburtsdatum': value['geburtsdatum'] == null ? undefined : ((value['geburtsdatum']).toISOString().substring(0,10)),
        'berechneUnsichtbarenLebensbaum': value['berechneUnsichtbarenLebensbaum'],
        'rufname': value['rufname'],
        'ehename': value['ehename'],
        'activation': value['activation'],
        'benutzeVornameAlsRufname': value['benutzeVornameAlsRufname'],
        'benutzeNachnameAlsEhename': value['benutzeNachnameAlsEhename'],
    };
}

