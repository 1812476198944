import { Box } from "@components/navbar/Box";
import { ReactNode } from "react";

interface LayoutProps {
    children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
    return (
        <Box
            css={{
                maxW: "100%"
            }}>
            {children}
        </Box>
    );
};
