import * as runtime from '../client/runtime'
import {
  EmotionalerSpiegelControllerApi,
  EnergiebildAchsenControllerApi, EnergiebildControllerApi,
  KarmischesPotentialControllerApi,
  LebensbaumWegeControllerApi,
  LernaufgabenControllerApi,
  LunareZahlenControllerApi,
  PentagrammControllerApi,
  PolareEntsprechungControllerApi,
  SeelenbildZahlenControllerApi,
  SolareZahlenControllerApi,
  SymptomeControllerApi,
  UnsichtbarerLebensbaumControllerApi,
  UnsichtbarerLebensbaumSephirothControllerApi, UserControllerApi
} from "../client";

export const apiConfig = new runtime.Configuration({
  basePath: `${process.env.REACT_APP_REST_API_BASE_URL}`,
  credentials: 'include'
  // weitere config parameter
});

/**
 *
 * const basePath = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';
 *
 * .env.development:
 * REACT_APP_API_BASE_URL=http://localhost:8080
 *
 * .env.production:
 * REACT_APP_API_BASE_URL=https://your-production-api.com
 */

  // TODO move to a separate config file etc.
export const controllerList = [
    {
      name: 'Benutzer',
      apiController: new UserControllerApi(apiConfig),
      getMethod: 'getAllUsers',
      updateMethod: 'updateUser',
      updateDataField: 'updateUserRequest'
    },
    {
      name: 'Unsichtbarer Lebensbaum Sephiroth',
      apiController: new UnsichtbarerLebensbaumSephirothControllerApi(apiConfig),
      getMethod: 'getAllUnsichtbarerLebensbaumSephiroth',
      updateMethod: 'updateUnsichtbarerLebensbaumSephiroth',
      updateDataField: 'unsichtbarerLebensbaumSephiroth'
    },
    {
      name: 'Unsichtbarer Lebensbaum',
      apiController: new UnsichtbarerLebensbaumControllerApi(apiConfig),
      getMethod: 'getAllUnsichtbarerLebensbaum',
      updateMethod: 'updateUnsichtbarerLebensbaum',
      updateDataField: 'unsichtbarerLebensbaum'
    },
    {
      name: 'Lernaufgaben',
      apiController: new SymptomeControllerApi(apiConfig),
      getMethod: 'getAllSymptome',
      updateMethod: 'updateSymptome',
      updateDataField: 'symptome'
    },
    {
      name: 'Solare Zahlen',
      apiController: new SolareZahlenControllerApi(apiConfig),
      getMethod: 'getAllSolareZahlen',
      updateMethod: 'updateSolareZahlen',
      updateDataField: 'solareZahlen'
    },
    {
      name: 'Seelenbild Zahlen',
      apiController: new SeelenbildZahlenControllerApi(apiConfig),
      getMethod: 'getAllSeelenbildZahlen',
      updateMethod: 'updateSeelenbildZahlen',
      updateDataField: 'seelenbildZahlen'
    },
    {
      name: 'Polare Entsprechung',
      apiController: new PolareEntsprechungControllerApi(apiConfig),
      getMethod: 'getAllPolareEntsprechungen',
      updateMethod: 'updatePolareEntsprechung',
      updateDataField: 'polareEntsprechung'
    },
    {
      name: 'Pentagramm',
      apiController: new PentagrammControllerApi(apiConfig),
      getMethod: 'getAllPentagramme',
      updateMethod: 'updatePentagramm',
      updateDataField: 'pentagramm'
    },
    {
      name: 'Lunare Zahlen',
      apiController: new LunareZahlenControllerApi(apiConfig),
      getMethod: 'getAllLunareZahlen',
      updateMethod: 'updateLunareZahlen',
      updateDataField: 'lunareZahlen'
    },
    {
      name: 'Lernaufgaben',
      apiController: new LernaufgabenControllerApi(apiConfig),
      getMethod: 'getAllLernaufgaben',
      updateMethod: 'updateLernaufgabe',
      updateDataField: 'lernaufgaben'
    },
    {
      name: 'Lebensbaum Wege',
      apiController: new LebensbaumWegeControllerApi(apiConfig),
      getMethod: 'getAllLebensbaumWege',
      updateMethod: 'updateLebensbaumWege',
      updateDataField: 'lebensbaumWege'
    },
    {
      name: 'Karmisches Potential',
      apiController: new KarmischesPotentialControllerApi(apiConfig),
      getMethod: 'getAllKarmischesPotentiale',
      updateMethod: 'updateKarmischesPotential',
      updateDataField: 'karmischesPotential'
    },
    {
      name: 'Energiebild Achsen',
      apiController: new EnergiebildAchsenControllerApi(apiConfig),
      getMethod: 'getAllEnergiebildAchsen',
      updateMethod: 'updateEnergiebildAchse',
      updateDataField: 'energiebildAchsen'
    },
    {
      name: 'Energiebild',
      apiController: new EnergiebildControllerApi(apiConfig),
      getMethod: 'getAllEnergiebilder',
      updateMethod: 'updateEnergiebild',
      updateDataField: 'energiebild'
    },
    {
      name: 'EmotionalerSpiegel',
      apiController: new EmotionalerSpiegelControllerApi(apiConfig),
      getMethod: 'getAllEmotionalerSpiegel',
      updateMethod: 'updateEmotionalerSpiegel',
      updateDataField: 'emotionalerSpiegel'
    },
  ];
