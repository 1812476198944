/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Number } from './Number';
import {
    NumberFromJSON,
    NumberFromJSONTyped,
    NumberToJSON,
} from './Number';

/**
 * 
 * @export
 * @interface ConnectionLine
 */
export interface ConnectionLine {
    /**
     * 
     * @type {Number}
     * @memberof ConnectionLine
     */
    start?: Number;
    /**
     * 
     * @type {Number}
     * @memberof ConnectionLine
     */
    end?: Number;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectionLine
     */
    arrowAtEnd?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConnectionLine
     */
    type?: ConnectionLineTypeEnum;
}


/**
 * @export
 */
export const ConnectionLineTypeEnum = {
    Normal: 'NORMAL',
    Polar: 'POLAR'
} as const;
export type ConnectionLineTypeEnum = typeof ConnectionLineTypeEnum[keyof typeof ConnectionLineTypeEnum];


/**
 * Check if a given object implements the ConnectionLine interface.
 */
export function instanceOfConnectionLine(value: object): value is ConnectionLine {
    return true;
}

export function ConnectionLineFromJSON(json: any): ConnectionLine {
    return ConnectionLineFromJSONTyped(json, false);
}

export function ConnectionLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectionLine {
    if (json == null) {
        return json;
    }
    return {
        
        'start': json['start'] == null ? undefined : NumberFromJSON(json['start']),
        'end': json['end'] == null ? undefined : NumberFromJSON(json['end']),
        'arrowAtEnd': json['arrowAtEnd'] == null ? undefined : json['arrowAtEnd'],
        'type': json['type'] == null ? undefined : json['type'],
    };
}

export function ConnectionLineToJSON(value?: ConnectionLine | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'start': NumberToJSON(value['start']),
        'end': NumberToJSON(value['end']),
        'arrowAtEnd': value['arrowAtEnd'],
        'type': value['type'],
    };
}

