/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Lernaufgaben,
} from '../models/index';
import {
    LernaufgabenFromJSON,
    LernaufgabenToJSON,
} from '../models/index';

export interface CreateLernaufgabeRequest {
    lernaufgaben: Lernaufgaben;
}

export interface DeleteLernaufgabeRequest {
    id: number;
}

export interface GetLernaufgabeByIdRequest {
    id: number;
}

export interface UpdateLernaufgabeRequest {
    id: number;
    lernaufgaben: Lernaufgaben;
}

/**
 * 
 */
export class LernaufgabenControllerApi extends runtime.BaseAPI {

    /**
     */
    async createLernaufgabeRaw(requestParameters: CreateLernaufgabeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Lernaufgaben>> {
        if (requestParameters['lernaufgaben'] == null) {
            throw new runtime.RequiredError(
                'lernaufgaben',
                'Required parameter "lernaufgaben" was null or undefined when calling createLernaufgabe().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/lernaufgaben`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LernaufgabenToJSON(requestParameters['lernaufgaben']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LernaufgabenFromJSON(jsonValue));
    }

    /**
     */
    async createLernaufgabe(requestParameters: CreateLernaufgabeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Lernaufgaben> {
        const response = await this.createLernaufgabeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteLernaufgabeRaw(requestParameters: DeleteLernaufgabeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteLernaufgabe().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/lernaufgaben/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteLernaufgabe(requestParameters: DeleteLernaufgabeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLernaufgabeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllLernaufgabenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Lernaufgaben>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/lernaufgaben`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LernaufgabenFromJSON));
    }

    /**
     */
    async getAllLernaufgaben(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Lernaufgaben>> {
        const response = await this.getAllLernaufgabenRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getLernaufgabeByIdRaw(requestParameters: GetLernaufgabeByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Lernaufgaben>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getLernaufgabeById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/lernaufgaben/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LernaufgabenFromJSON(jsonValue));
    }

    /**
     */
    async getLernaufgabeById(requestParameters: GetLernaufgabeByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Lernaufgaben> {
        const response = await this.getLernaufgabeByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateLernaufgabeRaw(requestParameters: UpdateLernaufgabeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Lernaufgaben>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateLernaufgabe().'
            );
        }

        if (requestParameters['lernaufgaben'] == null) {
            throw new runtime.RequiredError(
                'lernaufgaben',
                'Required parameter "lernaufgaben" was null or undefined when calling updateLernaufgabe().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/lernaufgaben/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LernaufgabenToJSON(requestParameters['lernaufgaben']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LernaufgabenFromJSON(jsonValue));
    }

    /**
     */
    async updateLernaufgabe(requestParameters: UpdateLernaufgabeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Lernaufgaben> {
        const response = await this.updateLernaufgabeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
