/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PolareEntsprechung,
} from '../models/index';
import {
    PolareEntsprechungFromJSON,
    PolareEntsprechungToJSON,
} from '../models/index';

export interface CreatePolareEntsprechungRequest {
    polareEntsprechung: PolareEntsprechung;
}

export interface DeletePolareEntsprechungRequest {
    id: number;
}

export interface GetPolareEntsprechungByIdRequest {
    id: number;
}

export interface UpdatePolareEntsprechungRequest {
    id: number;
    polareEntsprechung: PolareEntsprechung;
}

/**
 * 
 */
export class PolareEntsprechungControllerApi extends runtime.BaseAPI {

    /**
     */
    async createPolareEntsprechungRaw(requestParameters: CreatePolareEntsprechungRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PolareEntsprechung>> {
        if (requestParameters['polareEntsprechung'] == null) {
            throw new runtime.RequiredError(
                'polareEntsprechung',
                'Required parameter "polareEntsprechung" was null or undefined when calling createPolareEntsprechung().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/polare_entsprechung`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PolareEntsprechungToJSON(requestParameters['polareEntsprechung']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolareEntsprechungFromJSON(jsonValue));
    }

    /**
     */
    async createPolareEntsprechung(requestParameters: CreatePolareEntsprechungRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PolareEntsprechung> {
        const response = await this.createPolareEntsprechungRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePolareEntsprechungRaw(requestParameters: DeletePolareEntsprechungRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deletePolareEntsprechung().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/polare_entsprechung/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePolareEntsprechung(requestParameters: DeletePolareEntsprechungRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePolareEntsprechungRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllPolareEntsprechungenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PolareEntsprechung>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/polare_entsprechung`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PolareEntsprechungFromJSON));
    }

    /**
     */
    async getAllPolareEntsprechungen(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PolareEntsprechung>> {
        const response = await this.getAllPolareEntsprechungenRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPolareEntsprechungByIdRaw(requestParameters: GetPolareEntsprechungByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PolareEntsprechung>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getPolareEntsprechungById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/polare_entsprechung/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolareEntsprechungFromJSON(jsonValue));
    }

    /**
     */
    async getPolareEntsprechungById(requestParameters: GetPolareEntsprechungByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PolareEntsprechung> {
        const response = await this.getPolareEntsprechungByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePolareEntsprechungRaw(requestParameters: UpdatePolareEntsprechungRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PolareEntsprechung>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updatePolareEntsprechung().'
            );
        }

        if (requestParameters['polareEntsprechung'] == null) {
            throw new runtime.RequiredError(
                'polareEntsprechung',
                'Required parameter "polareEntsprechung" was null or undefined when calling updatePolareEntsprechung().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/polare_entsprechung/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PolareEntsprechungToJSON(requestParameters['polareEntsprechung']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolareEntsprechungFromJSON(jsonValue));
    }

    /**
     */
    async updatePolareEntsprechung(requestParameters: UpdatePolareEntsprechungRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PolareEntsprechung> {
        const response = await this.updatePolareEntsprechungRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
