/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Pentagramm,
} from '../models/index';
import {
    PentagrammFromJSON,
    PentagrammToJSON,
} from '../models/index';

export interface CreatePentagrammRequest {
    pentagramm: Pentagramm;
}

export interface DeletePentagrammRequest {
    id: number;
}

export interface GetPentagrammByIdRequest {
    id: number;
}

export interface UpdatePentagrammRequest {
    id: number;
    pentagramm: Pentagramm;
}

/**
 * 
 */
export class PentagrammControllerApi extends runtime.BaseAPI {

    /**
     */
    async createPentagrammRaw(requestParameters: CreatePentagrammRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Pentagramm>> {
        if (requestParameters['pentagramm'] == null) {
            throw new runtime.RequiredError(
                'pentagramm',
                'Required parameter "pentagramm" was null or undefined when calling createPentagramm().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/pentagramm`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PentagrammToJSON(requestParameters['pentagramm']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PentagrammFromJSON(jsonValue));
    }

    /**
     */
    async createPentagramm(requestParameters: CreatePentagrammRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Pentagramm> {
        const response = await this.createPentagrammRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePentagrammRaw(requestParameters: DeletePentagrammRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deletePentagramm().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/pentagramm/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePentagramm(requestParameters: DeletePentagrammRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePentagrammRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllPentagrammeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Pentagramm>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/pentagramm`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PentagrammFromJSON));
    }

    /**
     */
    async getAllPentagramme(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Pentagramm>> {
        const response = await this.getAllPentagrammeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPentagrammByIdRaw(requestParameters: GetPentagrammByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Pentagramm>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getPentagrammById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/pentagramm/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PentagrammFromJSON(jsonValue));
    }

    /**
     */
    async getPentagrammById(requestParameters: GetPentagrammByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Pentagramm> {
        const response = await this.getPentagrammByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePentagrammRaw(requestParameters: UpdatePentagrammRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Pentagramm>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updatePentagramm().'
            );
        }

        if (requestParameters['pentagramm'] == null) {
            throw new runtime.RequiredError(
                'pentagramm',
                'Required parameter "pentagramm" was null or undefined when calling updatePentagramm().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/texts/pentagramm/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PentagrammToJSON(requestParameters['pentagramm']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PentagrammFromJSON(jsonValue));
    }

    /**
     */
    async updatePentagramm(requestParameters: UpdatePentagrammRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Pentagramm> {
        const response = await this.updatePentagrammRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
