/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Energiebild
 */
export interface Energiebild {
    /**
     * 
     * @type {number}
     * @memberof Energiebild
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Energiebild
     */
    lernaufgabeText: string;
    /**
     * 
     * @type {string}
     * @memberof Energiebild
     */
    lernaufgabeTitel: string;
    /**
     * 
     * @type {string}
     * @memberof Energiebild
     */
    symptomText: string;
    /**
     * 
     * @type {string}
     * @memberof Energiebild
     */
    symptomTitel: string;
    /**
     * 
     * @type {string}
     * @memberof Energiebild
     */
    talentText: string;
    /**
     * 
     * @type {string}
     * @memberof Energiebild
     */
    talentTitel: string;
}

/**
 * Check if a given object implements the Energiebild interface.
 */
export function instanceOfEnergiebild(value: object): value is Energiebild {
    if (!('lernaufgabeText' in value) || value['lernaufgabeText'] === undefined) return false;
    if (!('lernaufgabeTitel' in value) || value['lernaufgabeTitel'] === undefined) return false;
    if (!('symptomText' in value) || value['symptomText'] === undefined) return false;
    if (!('symptomTitel' in value) || value['symptomTitel'] === undefined) return false;
    if (!('talentText' in value) || value['talentText'] === undefined) return false;
    if (!('talentTitel' in value) || value['talentTitel'] === undefined) return false;
    return true;
}

export function EnergiebildFromJSON(json: any): Energiebild {
    return EnergiebildFromJSONTyped(json, false);
}

export function EnergiebildFromJSONTyped(json: any, ignoreDiscriminator: boolean): Energiebild {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'lernaufgabeText': json['lernaufgabeText'],
        'lernaufgabeTitel': json['lernaufgabeTitel'],
        'symptomText': json['symptomText'],
        'symptomTitel': json['symptomTitel'],
        'talentText': json['talentText'],
        'talentTitel': json['talentTitel'],
    };
}

export function EnergiebildToJSON(value?: Energiebild | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'lernaufgabeText': value['lernaufgabeText'],
        'lernaufgabeTitel': value['lernaufgabeTitel'],
        'symptomText': value['symptomText'],
        'symptomTitel': value['symptomTitel'],
        'talentText': value['talentText'],
        'talentTitel': value['talentTitel'],
    };
}

