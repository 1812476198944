/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Input } from './Input';
import {
    InputFromJSON,
    InputFromJSONTyped,
    InputToJSON,
} from './Input';

/**
 * 
 * @export
 * @interface CalculateRequest
 */
export interface CalculateRequest {
    /**
     * 
     * @type {Input}
     * @memberof CalculateRequest
     */
    input?: Input;
}

/**
 * Check if a given object implements the CalculateRequest interface.
 */
export function instanceOfCalculateRequest(value: object): value is CalculateRequest {
    return true;
}

export function CalculateRequestFromJSON(json: any): CalculateRequest {
    return CalculateRequestFromJSONTyped(json, false);
}

export function CalculateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalculateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'input': json['input'] == null ? undefined : InputFromJSON(json['input']),
    };
}

export function CalculateRequestToJSON(value?: CalculateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'input': InputToJSON(value['input']),
    };
}

