/* tslint:disable */
/* eslint-disable */
/**
 * Kabbala REST API
 * Rest API zur Berechnung der Kabbala und zur Anpassung von Kabbala-Texten
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: uuffel@web.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SolareZahl } from './SolareZahl';
import {
    SolareZahlFromJSON,
    SolareZahlFromJSONTyped,
    SolareZahlToJSON,
} from './SolareZahl';
import type { Symptom } from './Symptom';
import {
    SymptomFromJSON,
    SymptomFromJSONTyped,
    SymptomToJSON,
} from './Symptom';

/**
 * 
 * @export
 * @interface Person
 */
export interface Person {
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    geburtsNachname?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    geburtsVorname?: string;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    summeWeg1?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    ergebnisWeg1?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    ergebnisWeg1Durch9?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    ergebnisWeg1plus1?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    weg1?: number;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    textWeg1?: string;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    geburtsMonat?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    geburtsJahr?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    weg2?: number;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    textWeg2?: string;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    ergebnisWeg3?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    ergebnisWeg3Durch9?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    ergebnisWeg3plus1?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    weg3?: number;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    textWeg3?: string;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    ergebnisEnergiebild?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    ergebnisSeelenbild?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    wandlungszahlSeelenbild?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    partnerschaftsZahl?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Person
     */
    geteiltePartnerschaftsZahl?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    lebensmottoZahl?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Person
     */
    geteilteLebensmottoZahl?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    weg4?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    weg5?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    weg6?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    mindestalterWeg4?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    mindestalterWeg5?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    mindestalterWeg6?: number;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    textWeg4?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    textWeg5?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    textWeg6?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Person
     */
    talente?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Person
     */
    lernaufgabenEnergiebild?: Array<number>;
    /**
     * 
     * @type {Array<Symptom>}
     * @memberof Person
     */
    symptome?: Array<Symptom>;
    /**
     * 
     * @type {Array<SolareZahl>}
     * @memberof Person
     */
    solareZahlenEnergiebild?: Array<SolareZahl>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Person
     */
    lernthemenSeelenbild?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Person
     */
    geistigeHilfeSeelenbild?: Array<number>;
    /**
     * 
     * @type {Array<SolareZahl>}
     * @memberof Person
     */
    solareZahlenSeelenbild?: Array<SolareZahl>;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    emotionalerSpiegel?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Person
     */
    schwein?: boolean;
    /**
     * 
     * @type {Array<SolareZahl>}
     * @memberof Person
     */
    solareZahlenPentagramm?: Array<SolareZahl>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Person
     */
    haeuserPentagramm?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    rufname?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    ehename?: string;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    weg1UL?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    weg2UL?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    weg3UL?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    weg4UL?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    weg5UL?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    weg6UL?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    weg7UL?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    mindestalterWeg4UL?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    mindestalterWeg5UL?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    mindestalterWeg6UL?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    mindestalterWeg7UL?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Person
     */
    archaischesPotential?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Person
     */
    unbezahlteRechnungen?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    alter?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    geburtsMonatEnergiebild?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    geburtsJahrEnergiebild?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    geburtstagEnergiebild?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    geburtstag?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    quersummePentagramm?: number;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof Person
     */
    polareEntsprechungEnergiebild?: Array<Array<number>>;
    /**
     * 
     * @type {boolean}
     * @memberof Person
     */
    istSchwein?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    geburtstagSeelenbild?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    geburtsMonatSeelenbild?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    geburtsJahrSeelenbild?: number;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    lunareZahlenNachname?: string;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    anzahlNormaleWegeUL?: number;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    lunareZahlenVorname?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Person
     */
    haeuserPengtagramm?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    verspannungsZahl?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    querSummeWeg2?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    querSummeWeg1?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    querSummePin?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    summeEnergiebild?: number;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    summeSeelenbild?: number;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    geburtstagAsString?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Person
     */
    weg4Durch10er?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    pin?: number;
}

/**
 * Check if a given object implements the Person interface.
 */
export function instanceOfPerson(value: object): value is Person {
    return true;
}

export function PersonFromJSON(json: any): Person {
    return PersonFromJSONTyped(json, false);
}

export function PersonFromJSONTyped(json: any, ignoreDiscriminator: boolean): Person {
    if (json == null) {
        return json;
    }
    return {
        
        'geburtsNachname': json['geburtsNachname'] == null ? undefined : json['geburtsNachname'],
        'geburtsVorname': json['geburtsVorname'] == null ? undefined : json['geburtsVorname'],
        'summeWeg1': json['summeWeg1'] == null ? undefined : json['summeWeg1'],
        'ergebnisWeg1': json['ergebnisWeg1'] == null ? undefined : json['ergebnisWeg1'],
        'ergebnisWeg1Durch9': json['ergebnisWeg1Durch9'] == null ? undefined : json['ergebnisWeg1Durch9'],
        'ergebnisWeg1plus1': json['ergebnisWeg1plus1'] == null ? undefined : json['ergebnisWeg1plus1'],
        'weg1': json['weg1'] == null ? undefined : json['weg1'],
        'textWeg1': json['textWeg1'] == null ? undefined : json['textWeg1'],
        'geburtsMonat': json['geburtsMonat'] == null ? undefined : json['geburtsMonat'],
        'geburtsJahr': json['geburtsJahr'] == null ? undefined : json['geburtsJahr'],
        'weg2': json['weg2'] == null ? undefined : json['weg2'],
        'textWeg2': json['textWeg2'] == null ? undefined : json['textWeg2'],
        'ergebnisWeg3': json['ergebnisWeg3'] == null ? undefined : json['ergebnisWeg3'],
        'ergebnisWeg3Durch9': json['ergebnisWeg3Durch9'] == null ? undefined : json['ergebnisWeg3Durch9'],
        'ergebnisWeg3plus1': json['ergebnisWeg3plus1'] == null ? undefined : json['ergebnisWeg3plus1'],
        'weg3': json['weg3'] == null ? undefined : json['weg3'],
        'textWeg3': json['textWeg3'] == null ? undefined : json['textWeg3'],
        'ergebnisEnergiebild': json['ergebnisEnergiebild'] == null ? undefined : json['ergebnisEnergiebild'],
        'ergebnisSeelenbild': json['ergebnisSeelenbild'] == null ? undefined : json['ergebnisSeelenbild'],
        'wandlungszahlSeelenbild': json['wandlungszahlSeelenbild'] == null ? undefined : json['wandlungszahlSeelenbild'],
        'partnerschaftsZahl': json['partnerschaftsZahl'] == null ? undefined : json['partnerschaftsZahl'],
        'geteiltePartnerschaftsZahl': json['geteiltePartnerschaftsZahl'] == null ? undefined : json['geteiltePartnerschaftsZahl'],
        'lebensmottoZahl': json['lebensmottoZahl'] == null ? undefined : json['lebensmottoZahl'],
        'geteilteLebensmottoZahl': json['geteilteLebensmottoZahl'] == null ? undefined : json['geteilteLebensmottoZahl'],
        'weg4': json['weg4'] == null ? undefined : json['weg4'],
        'weg5': json['weg5'] == null ? undefined : json['weg5'],
        'weg6': json['weg6'] == null ? undefined : json['weg6'],
        'mindestalterWeg4': json['mindestalterWeg4'] == null ? undefined : json['mindestalterWeg4'],
        'mindestalterWeg5': json['mindestalterWeg5'] == null ? undefined : json['mindestalterWeg5'],
        'mindestalterWeg6': json['mindestalterWeg6'] == null ? undefined : json['mindestalterWeg6'],
        'textWeg4': json['textWeg4'] == null ? undefined : json['textWeg4'],
        'textWeg5': json['textWeg5'] == null ? undefined : json['textWeg5'],
        'textWeg6': json['textWeg6'] == null ? undefined : json['textWeg6'],
        'talente': json['talente'] == null ? undefined : json['talente'],
        'lernaufgabenEnergiebild': json['lernaufgabenEnergiebild'] == null ? undefined : json['lernaufgabenEnergiebild'],
        'symptome': json['symptome'] == null ? undefined : ((json['symptome'] as Array<any>).map(SymptomFromJSON)),
        'solareZahlenEnergiebild': json['solareZahlenEnergiebild'] == null ? undefined : ((json['solareZahlenEnergiebild'] as Array<any>).map(SolareZahlFromJSON)),
        'lernthemenSeelenbild': json['lernthemenSeelenbild'] == null ? undefined : json['lernthemenSeelenbild'],
        'geistigeHilfeSeelenbild': json['geistigeHilfeSeelenbild'] == null ? undefined : json['geistigeHilfeSeelenbild'],
        'solareZahlenSeelenbild': json['solareZahlenSeelenbild'] == null ? undefined : ((json['solareZahlenSeelenbild'] as Array<any>).map(SolareZahlFromJSON)),
        'emotionalerSpiegel': json['emotionalerSpiegel'] == null ? undefined : json['emotionalerSpiegel'],
        'schwein': json['schwein'] == null ? undefined : json['schwein'],
        'solareZahlenPentagramm': json['solareZahlenPentagramm'] == null ? undefined : ((json['solareZahlenPentagramm'] as Array<any>).map(SolareZahlFromJSON)),
        'haeuserPentagramm': json['haeuserPentagramm'] == null ? undefined : json['haeuserPentagramm'],
        'rufname': json['rufname'] == null ? undefined : json['rufname'],
        'ehename': json['ehename'] == null ? undefined : json['ehename'],
        'weg1UL': json['weg1_UL'] == null ? undefined : json['weg1_UL'],
        'weg2UL': json['weg2_UL'] == null ? undefined : json['weg2_UL'],
        'weg3UL': json['weg3_UL'] == null ? undefined : json['weg3_UL'],
        'weg4UL': json['weg4_UL'] == null ? undefined : json['weg4_UL'],
        'weg5UL': json['weg5_UL'] == null ? undefined : json['weg5_UL'],
        'weg6UL': json['weg6_UL'] == null ? undefined : json['weg6_UL'],
        'weg7UL': json['weg7_UL'] == null ? undefined : json['weg7_UL'],
        'mindestalterWeg4UL': json['mindestalterWeg4_UL'] == null ? undefined : json['mindestalterWeg4_UL'],
        'mindestalterWeg5UL': json['mindestalterWeg5_UL'] == null ? undefined : json['mindestalterWeg5_UL'],
        'mindestalterWeg6UL': json['mindestalterWeg6_UL'] == null ? undefined : json['mindestalterWeg6_UL'],
        'mindestalterWeg7UL': json['mindestalterWeg7_UL'] == null ? undefined : json['mindestalterWeg7_UL'],
        'archaischesPotential': json['archaischesPotential'] == null ? undefined : json['archaischesPotential'],
        'unbezahlteRechnungen': json['unbezahlteRechnungen'] == null ? undefined : json['unbezahlteRechnungen'],
        'alter': json['alter'] == null ? undefined : json['alter'],
        'geburtsMonatEnergiebild': json['geburtsMonatEnergiebild'] == null ? undefined : json['geburtsMonatEnergiebild'],
        'geburtsJahrEnergiebild': json['geburtsJahrEnergiebild'] == null ? undefined : json['geburtsJahrEnergiebild'],
        'geburtstagEnergiebild': json['geburtstagEnergiebild'] == null ? undefined : json['geburtstagEnergiebild'],
        'geburtstag': json['geburtstag'] == null ? undefined : json['geburtstag'],
        'quersummePentagramm': json['quersummePentagramm'] == null ? undefined : json['quersummePentagramm'],
        'polareEntsprechungEnergiebild': json['polareEntsprechungEnergiebild'] == null ? undefined : json['polareEntsprechungEnergiebild'],
        'istSchwein': json['istSchwein'] == null ? undefined : json['istSchwein'],
        'geburtstagSeelenbild': json['geburtstagSeelenbild'] == null ? undefined : json['geburtstagSeelenbild'],
        'geburtsMonatSeelenbild': json['geburtsMonatSeelenbild'] == null ? undefined : json['geburtsMonatSeelenbild'],
        'geburtsJahrSeelenbild': json['geburtsJahrSeelenbild'] == null ? undefined : json['geburtsJahrSeelenbild'],
        'lunareZahlenNachname': json['lunareZahlenNachname'] == null ? undefined : json['lunareZahlenNachname'],
        'anzahlNormaleWegeUL': json['anzahl_normaleWege_UL'] == null ? undefined : json['anzahl_normaleWege_UL'],
        'lunareZahlenVorname': json['lunareZahlenVorname'] == null ? undefined : json['lunareZahlenVorname'],
        'haeuserPengtagramm': json['haeuserPengtagramm'] == null ? undefined : json['haeuserPengtagramm'],
        'verspannungsZahl': json['verspannungsZahl'] == null ? undefined : json['verspannungsZahl'],
        'querSummeWeg2': json['querSummeWeg2'] == null ? undefined : json['querSummeWeg2'],
        'querSummeWeg1': json['querSummeWeg1'] == null ? undefined : json['querSummeWeg1'],
        'querSummePin': json['querSummePin'] == null ? undefined : json['querSummePin'],
        'summeEnergiebild': json['summeEnergiebild'] == null ? undefined : json['summeEnergiebild'],
        'summeSeelenbild': json['summeSeelenbild'] == null ? undefined : json['summeSeelenbild'],
        'geburtstagAsString': json['geburtstag_asString'] == null ? undefined : json['geburtstag_asString'],
        'weg4Durch10er': json['_Weg4_durch_10er'] == null ? undefined : json['_Weg4_durch_10er'],
        'pin': json['pin'] == null ? undefined : json['pin'],
    };
}

export function PersonToJSON(value?: Person | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'geburtsNachname': value['geburtsNachname'],
        'geburtsVorname': value['geburtsVorname'],
        'summeWeg1': value['summeWeg1'],
        'ergebnisWeg1': value['ergebnisWeg1'],
        'ergebnisWeg1Durch9': value['ergebnisWeg1Durch9'],
        'ergebnisWeg1plus1': value['ergebnisWeg1plus1'],
        'weg1': value['weg1'],
        'textWeg1': value['textWeg1'],
        'geburtsMonat': value['geburtsMonat'],
        'geburtsJahr': value['geburtsJahr'],
        'weg2': value['weg2'],
        'textWeg2': value['textWeg2'],
        'ergebnisWeg3': value['ergebnisWeg3'],
        'ergebnisWeg3Durch9': value['ergebnisWeg3Durch9'],
        'ergebnisWeg3plus1': value['ergebnisWeg3plus1'],
        'weg3': value['weg3'],
        'textWeg3': value['textWeg3'],
        'ergebnisEnergiebild': value['ergebnisEnergiebild'],
        'ergebnisSeelenbild': value['ergebnisSeelenbild'],
        'wandlungszahlSeelenbild': value['wandlungszahlSeelenbild'],
        'partnerschaftsZahl': value['partnerschaftsZahl'],
        'geteiltePartnerschaftsZahl': value['geteiltePartnerschaftsZahl'],
        'lebensmottoZahl': value['lebensmottoZahl'],
        'geteilteLebensmottoZahl': value['geteilteLebensmottoZahl'],
        'weg4': value['weg4'],
        'weg5': value['weg5'],
        'weg6': value['weg6'],
        'mindestalterWeg4': value['mindestalterWeg4'],
        'mindestalterWeg5': value['mindestalterWeg5'],
        'mindestalterWeg6': value['mindestalterWeg6'],
        'textWeg4': value['textWeg4'],
        'textWeg5': value['textWeg5'],
        'textWeg6': value['textWeg6'],
        'talente': value['talente'],
        'lernaufgabenEnergiebild': value['lernaufgabenEnergiebild'],
        'symptome': value['symptome'] == null ? undefined : ((value['symptome'] as Array<any>).map(SymptomToJSON)),
        'solareZahlenEnergiebild': value['solareZahlenEnergiebild'] == null ? undefined : ((value['solareZahlenEnergiebild'] as Array<any>).map(SolareZahlToJSON)),
        'lernthemenSeelenbild': value['lernthemenSeelenbild'],
        'geistigeHilfeSeelenbild': value['geistigeHilfeSeelenbild'],
        'solareZahlenSeelenbild': value['solareZahlenSeelenbild'] == null ? undefined : ((value['solareZahlenSeelenbild'] as Array<any>).map(SolareZahlToJSON)),
        'emotionalerSpiegel': value['emotionalerSpiegel'],
        'schwein': value['schwein'],
        'solareZahlenPentagramm': value['solareZahlenPentagramm'] == null ? undefined : ((value['solareZahlenPentagramm'] as Array<any>).map(SolareZahlToJSON)),
        'haeuserPentagramm': value['haeuserPentagramm'],
        'rufname': value['rufname'],
        'ehename': value['ehename'],
        'weg1_UL': value['weg1UL'],
        'weg2_UL': value['weg2UL'],
        'weg3_UL': value['weg3UL'],
        'weg4_UL': value['weg4UL'],
        'weg5_UL': value['weg5UL'],
        'weg6_UL': value['weg6UL'],
        'weg7_UL': value['weg7UL'],
        'mindestalterWeg4_UL': value['mindestalterWeg4UL'],
        'mindestalterWeg5_UL': value['mindestalterWeg5UL'],
        'mindestalterWeg6_UL': value['mindestalterWeg6UL'],
        'mindestalterWeg7_UL': value['mindestalterWeg7UL'],
        'archaischesPotential': value['archaischesPotential'],
        'unbezahlteRechnungen': value['unbezahlteRechnungen'],
        'alter': value['alter'],
        'geburtsMonatEnergiebild': value['geburtsMonatEnergiebild'],
        'geburtsJahrEnergiebild': value['geburtsJahrEnergiebild'],
        'geburtstagEnergiebild': value['geburtstagEnergiebild'],
        'geburtstag': value['geburtstag'],
        'quersummePentagramm': value['quersummePentagramm'],
        'polareEntsprechungEnergiebild': value['polareEntsprechungEnergiebild'],
        'istSchwein': value['istSchwein'],
        'geburtstagSeelenbild': value['geburtstagSeelenbild'],
        'geburtsMonatSeelenbild': value['geburtsMonatSeelenbild'],
        'geburtsJahrSeelenbild': value['geburtsJahrSeelenbild'],
        'lunareZahlenNachname': value['lunareZahlenNachname'],
        'anzahl_normaleWege_UL': value['anzahlNormaleWegeUL'],
        'lunareZahlenVorname': value['lunareZahlenVorname'],
        'haeuserPengtagramm': value['haeuserPengtagramm'],
        'verspannungsZahl': value['verspannungsZahl'],
        'querSummeWeg2': value['querSummeWeg2'],
        'querSummeWeg1': value['querSummeWeg1'],
        'querSummePin': value['querSummePin'],
        'summeEnergiebild': value['summeEnergiebild'],
        'summeSeelenbild': value['summeSeelenbild'],
        'geburtstag_asString': value['geburtstagAsString'],
        '_Weg4_durch_10er': value['weg4Durch10er'],
        'pin': value['pin'],
    };
}

