import { Navbar, Text, Link, Button } from "@nextui-org/react";
import { InfoModal } from "@components/navbar/InfoModal";
import { ThemeSwitch } from "@components/theme/ThemeSwitch";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useHookstate } from "@hookstate/core";
import { initialLoggedInUserState, loggedInUserState } from "@states/LoggedInUserState";
import { AuthControllerApi } from "../../client";
import { apiConfig } from "@api/configuration";

export const NavigationBar = () => {
    const authApi = new AuthControllerApi(apiConfig);
    const loggedInUser = useHookstate(loggedInUserState);
    const isAdmin = loggedInUser.get() && loggedInUser.get().roles.includes("ROLE_ADMIN");
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogoutButton = () => {
        authApi.logoutUser().then(() => { // also tell the api that we want to log out
            loggedInUser.set(initialLoggedInUserState); // delete stored user
            window.location.reload(); // a reload should cause the router to go to /login
        });
    }

    const collapseItems = [
        { label: "Eingabe", path: "/" },
        { label: "Lebensbaum", path: "/lebensbaum" },
        { label: "Info", path: "/info" },
        { label: "Energiebild", path: "/energiebild" },
        { label: "Seelenbild", path: "/seelenbild" },
        { label: "Karmische Wiederbegegnung", path: "/karmische-wiederbegegnung" },
        { label: "Pentagramm", path: "/pentagramm"},
        { label: "Über uns", path: "/uber-uns" },
        { label: "Abmelden", action: handleLogoutButton}
    ];

    return <Navbar isBordered variant="sticky">
        <Navbar.Brand>
            <Navbar.Toggle showIn="md" aria-label="toggle navigation" css={{paddingRight: "12px"}}/>
            <Text h4 css={{color: "#0072F5", marginBottom: 0}}>Kabbala</Text>
        </Navbar.Brand>
        <Navbar.Content hideIn="md" variant="underline">
            <Navbar.Link isActive={location.pathname === '/'} onClick={() => navigate("/")}>Eingabe</Navbar.Link>
            <Navbar.Link isActive={location.pathname === '/lebensbaum'} onClick={() => navigate("/lebensbaum")}>Lebensbaum</Navbar.Link>
            <Navbar.Link isActive={location.pathname === '/info'} onClick={() => navigate("/info")}>Info</Navbar.Link>
            <Navbar.Link isActive={location.pathname === '/energiebild'} onClick={() => navigate("/energiebild")}>Energiebild</Navbar.Link>
            <Navbar.Link isActive={location.pathname === '/seelenbild'} onClick={() => navigate("/seelenbild")}>Seelenbild</Navbar.Link>
            <Navbar.Link isActive={location.pathname === '/karmische-wiederbegegnung'} onClick={() => navigate("/karmische-wiederbegegnung")}>Karmische Wiederbegegnung</Navbar.Link>
            <Navbar.Link isActive={location.pathname === '/pentagramm'} onClick={() => navigate("/pentagramm")}>Pentagramm</Navbar.Link>
        </Navbar.Content>
        <Navbar.Content>
            { isAdmin &&
              <Navbar.Item>
                  <Button auto color="warning" flat as={Link} href="/admin">
                      Adminbereich
                  </Button>
              </Navbar.Item>
            }
        </Navbar.Content>
        <Navbar.Content hideIn="md">
            <Navbar.Item>
                <Button auto flat as={Link} href="#" onClick={handleLogoutButton}>
                    Abmelden
                </Button>
            </Navbar.Item>
        </Navbar.Content>

        <Navbar.Content hideIn="md">
            <Navbar.Item>
                <InfoModal />
            </Navbar.Item>
            <Navbar.Item>
                <ThemeSwitch />
            </Navbar.Item>
        </Navbar.Content>

        <Navbar.Collapse>
            {collapseItems.map((item, index) => (
              <Navbar.CollapseItem key={index}>
                  {/* Unterscheidung zwischen Link und Aktion */}
                  {item.path ? (
                    <Link
                      color="inherit"
                      css={{ minWidth: "100%" }}
                      href={item.path}
                    >
                        {item.label}
                    </Link>
                  ) : (
                    <Link
                      color="inherit"
                      css={{ minWidth: "100%" }}
                      onClick={item.action} // Aufruf der Aktion
                    >
                        {item.label}
                    </Link>
                  )}
              </Navbar.CollapseItem>
            ))}
        </Navbar.Collapse>
    </Navbar>
}
