import React from 'react';
import { Table } from "@nextui-org/react";
import { useHookstate } from "@hookstate/core";
import { calculationRequestHistoryState } from "@states/CalculationRequestHistoryState";
import { CalculateOperationRequest } from "../../../client";
import { Immutable } from "@hookstate/core";
import { format } from 'date-fns';
import { NoDataContainer } from "@components/routes/comps/NoDataContainer"; // Importieren Sie die Formatierungs-Funktionen aus date-fns oder einer anderen Bibliothek

type TableColumn = {
  key: string;
  label: string;
};

type TableRow = {
  key: string;
  [key: string]: any;
};

export const CalculationHistoryTable:({ onSelectionChange }: { onSelectionChange: any }) => React.JSX.Element = ({ onSelectionChange }) => {
  const calculationState = useHookstate(calculationRequestHistoryState);
  const calcHistory: Immutable<CalculateOperationRequest[]> = calculationState.get();

  const columns: TableColumn[] = calcHistory.length > 0 && calcHistory[0].calculateRequest.input
    ? Object.keys(calcHistory[0].calculateRequest.input).map((key) => ({
      key,
      label: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'),
    }))
    : [];

  const rows: TableRow[] = calcHistory.map((item, index) => {
    const formattedItem: TableRow = {
      key: index.toString(),
      ...item.calculateRequest.input
    };

    // Überprüfen Sie, ob item.calculateRequest und item.calculateRequest.input definiert sind
    if (item.calculateRequest && item.calculateRequest.input && item.calculateRequest.input.geburtsdatum) {
      formattedItem.geburtsdatum = format(new Date(item.calculateRequest.input.geburtsdatum), 'dd.MM.yyyy');
    }

    return formattedItem;
  });

  return (
    <>
      {calcHistory.length > 0 ? (
        <Table
          aria-label="Example table with dynamic content"
          css={{
            height: "auto",
            minWidth: "100%",
          }}
          selectionMode="single"
          onSelectionChange={(keys) => onSelectionChange(keys)}
        >
          <Table.Header columns={columns}>
            {(column) => (
              <Table.Column key={column.key}>{column.label}</Table.Column>
            )}
          </Table.Header>
          <Table.Body items={rows}>
            {(item) => (
              <Table.Row key={item.key}>
                {(columnKey) => <Table.Cell>{String(item[columnKey as keyof TableRow])}</Table.Cell>}
              </Table.Row>
            )}
          </Table.Body>
          <Table.Pagination
            shadow
            noMargin
            align="center"
            rowsPerPage={7}
            onPageChange={(page) => console.log({ page })}
          />
        </Table>
      ) : (
        <NoDataContainer height="auto" width="40%" />
      )}</>
  );
};