import { Badge, Card, Grid, Text } from "@nextui-org/react";
import React from "react";
import { NoDataFoundIllustration } from "@components/routes/comps/NoDataFoundIllustration";

interface Props {
      height: string;
      width: string;
}

export const NoDataContainer = ({height, width}: Props) => {
      return (
            <Card>
              <Card.Body>
                <Grid.Container gap={2}>
                      <Grid xs={12} justify="center" alignItems="center">
                            <NoDataFoundIllustration width={width} height={height} />
                      </Grid>
                      <Grid xs={12} justify="center" alignItems="center">
                            <Badge enableShadow disableOutline color="warning">
                                  Keine Daten
                            </Badge>
                      </Grid>
                      <Grid xs={12} justify="center" alignItems="center">
                            <Text>Sie haben noch keine Berechnung durchgeführt</Text>
                      </Grid>
                </Grid.Container>
              </Card.Body>
            </Card>
      );
}
