import React, { useState } from "react";
import { LebensbaumSvg } from "@components/routes/comps/LebensbaumSvg";
import {
    Badge,
    Button,
    Card,
    Container,
    Grid,
    Modal,
    Row,
    Spacer,
    Text,
    useModal,
} from "@nextui-org/react";
import { useHookstate } from "@hookstate/core";
import { calculationHistoryState } from "@states/CalculationHistoryState";
import { LebensbaumWegeControllerApi, UnsichtbarerLebensbaumControllerApi } from "../../client";
import { apiConfig } from "@api/configuration";
import { DEFAULT_TOASTER_OPTIONS } from "@utils/constants";
import { toast } from "react-toastify";

const planetenNamen: Record<number, string> = {
    1: "Kether",
    2: "Chokmah",
    3: "Binah",
    4: "Chesed",
    5: "Geburah",
    6: "Tipheret",
    7: "Nezach",
    8: "Hod",
    9: "Jesod",
    10: "Malkuth",
};

const getPlanetNameById = (planetId: number): string | undefined => {
    return planetenNamen[planetId];
};

export const Lebensbaum = () => {
    const [selectUnsichtbarerLebensbaum, setSelectUnsichtbarerLebensbaum] = useState(false);

    const { setVisible, bindings } = useModal();
    const calculationState = useHookstate(calculationHistoryState);
    const lebensbaumApi = new LebensbaumWegeControllerApi(apiConfig);
    const unsichtbarerLebensbaumApi = new UnsichtbarerLebensbaumControllerApi(apiConfig);

    const latestCalculationResult = calculationState.get().length > 0 ? calculationState.get()[calculationState.get().length - 1] : null;
    const person = latestCalculationResult?.person;
    const weg1 = person?.weg1 ?? -1;
    const weg2 = person?.weg2 ?? -1;
    const weg3 = person?.weg3 ?? -1;
    const weg1Text = person?.textWeg1;
    const weg2Text = person?.textWeg2;
    const weg3Text = person?.textWeg3;
    const weg1UL = person?.weg1UL ?? -1;
    const weg2UL = person?.weg2UL ?? -1;
    const weg3UL = person?.weg3UL ?? -1;
    const [weg1ULText, setWeg1ULText] = useState("");
    const [weg2ULText, setWeg2ULText] = useState("");
    const [weg3ULText, setWeg3ULText] = useState("");

    unsichtbarerLebensbaumApi.getUnsichtbarerLebensbaumById({ id: weg1UL }).then( (res) => {
        setWeg1ULText(res.text);
    }).catch((err) => console.error(err));
    unsichtbarerLebensbaumApi.getUnsichtbarerLebensbaumById({ id: weg2UL }).then( (res) => {
        setWeg2ULText(res.text);
    }).catch((err) => console.error(err));
    unsichtbarerLebensbaumApi.getUnsichtbarerLebensbaumById({ id: weg3UL }).then( (res) => {
        setWeg3ULText(res.text);
    }).catch((err) => console.error(err));

    const [clickedWegLernaufgabe, setClickedWegLernaufgabe] = useState("");
    const [clickedWegTalent, setClickedWegTalent] = useState("");

    const wegClickHandler = (wegId: number) => {
        console.log("Weg geklickt");
        console.log(wegId);

        lebensbaumApi.getLebensbaumWegeById({ id: Number(wegId) + 1 }).then( (res) => {
            setClickedWegLernaufgabe(res.lernaufgabe);
            setClickedWegTalent(res.talent);
            setVisible(true);

        }
        ).catch((err) => console.error(err));

    }

    const planetClickHandler = (planetId: number) => {
        console.log("Planet geklickt");
        console.log(planetId);
        console.log(getPlanetNameById(planetId));
        toast.info("Planet " + getPlanetNameById(planetId), DEFAULT_TOASTER_OPTIONS);
        //setVisible(true);
    }

    return (
        <>
            {/*Modal für Lebensbaumwege*/}
            <Modal
              scroll
              width="600px"
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
              {...bindings}>
                <Modal.Header>
                    <Text id="modal-title" size={18}>
                        Info
                    </Text>
                </Modal.Header>
                <Modal.Body>
                    <Text h3>Lernaufgabe</Text>
                    <Text blockquote css={{ marginTop: "0px" }}>
                        {clickedWegLernaufgabe}
                    </Text>
                    <Text h3>Talent</Text>
                    <Text blockquote>
                        {clickedWegTalent}
                    </Text>
                </Modal.Body>
                <Modal.Footer>
                    <Button auto onPress={() => setVisible(false)}>
                        Schließen
                    </Button>
                </Modal.Footer>
            </Modal>


        <Container css={{maxWidth: "1400px"}}>
            <Spacer />
            <Spacer />

            {/* Heading */}
            <Text
                h1
                size={48}
                css={{ textGradient: "45deg, $blue600 -20%, $pink600 50%", marginBottom: 0}}
                weight="bold">
                Lebensbaum
            </Text>

            <Spacer y={1}></Spacer>
                    {/*<CircleDiagram data={circleData}></CircleDiagram>*/}

            <Grid.Container gap={1}>
                <Grid xs={12} md={8}>
                    <Card>
                        <Card.Header>
                            <Row justify="flex-end">
                                <Button.Group size="sm">
                                    <Button color={"secondary"} flat={selectUnsichtbarerLebensbaum} onPress={() => setSelectUnsichtbarerLebensbaum(false)}>
                                        Lebensbaum
                                    </Button>
                                    <Button color={"secondary"} flat={!selectUnsichtbarerLebensbaum} disabled={false} onPress={() => setSelectUnsichtbarerLebensbaum(true)}>Unsichtbarer Lebensbaum</Button>
                                </Button.Group>
                            </Row>
                        </Card.Header>
                        <Card.Body  style={{
                            display: 'flex',
                            justifyContent: 'center', // Horizontally center the content
                            alignItems: 'center',     // Vertically center the content
                        }}>

                            {selectUnsichtbarerLebensbaum ? (
                              <LebensbaumSvg test="Hallo Welt" weg1={weg1UL} weg2={weg2UL} weg3={weg3UL} handleLebensbaumWegClick={wegClickHandler} handlePlanetClick={planetClickHandler}/>
                            ) : (
                              <LebensbaumSvg test="Hallo Welt" weg1={weg1} weg2={weg2} weg3={weg3} handleLebensbaumWegClick={wegClickHandler} handlePlanetClick={planetClickHandler}/>
                            )}
                           </Card.Body>
                    </Card>
                </Grid>
                <Grid xs={12} md={4}>
                    <Card>
                        <Card.Body>
                            {/*<Text h4>Legende</Text>
                            <Grid.Container gap={0.5}>
                                <Grid xs={12} alignItems="center">
                                    <Badge variant="dot" />
                                    <Text css={{ ml: "$2" }}>Weg 1</Text>
                                </Grid>
                                <Grid xs={12} alignItems="center">
                                    <Badge color="primary" variant="dot" />
                                    <Text css={{ ml: "$2" }}>Weg 2</Text>
                                </Grid>
                                <Grid xs={12} alignItems="center">
                                    <Badge color="secondary" variant="dot" />
                                    <Text css={{ ml: "$2" }}>Weg 3</Text>
                                </Grid>
                            </Grid.Container>
                            <Spacer />*/}
                            <Text h4>Legende</Text>
                            <Grid.Container gap={0.5}>
                                <Grid xs={12} alignItems="center">
                                    <Badge css={{backgroundColor: "#AC87C5"}} variant="dot" />
                                    <Text css={{ ml: "$2" }}>Weg 1 - {selectUnsichtbarerLebensbaum ? weg1ULText : weg1Text}</Text>
                                </Grid>
                                <Grid xs={12} alignItems="center">
                                    <Badge css={{backgroundColor: "#E0AED0"}} variant="dot" />
                                    <Text css={{ ml: "$2" }}>Weg 2 - {selectUnsichtbarerLebensbaum ? weg2ULText :weg2Text}</Text>
                                </Grid>
                                <Grid xs={12} alignItems="center">
                                    <Badge css={{backgroundColor: "#FFE5E5"}} variant="dot" />
                                    <Text css={{ ml: "$2" }}>Weg 3 - {selectUnsichtbarerLebensbaum ? weg3ULText :weg3Text}</Text>
                                </Grid>
                            </Grid.Container>
                        </Card.Body>
                    </Card>
                </Grid>
            </Grid.Container>

            <Spacer />
            </Container>

        </>
        
    );
}