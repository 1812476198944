import "./comps/energiebild.css";
import { Spacer, Text, Container, Card } from "@nextui-org/react";
import { useHookstate } from "@hookstate/core";
import { calculationHistoryState } from "@states/CalculationHistoryState";
import { useEffect, useState } from "react";
import { NoDataContainer } from "@components/routes/comps/NoDataContainer";

export const Pentagramm = () => {
    const calculationState = useHookstate(calculationHistoryState);
    const latestCalculationResult = calculationState.get()[calculationState.length - 1];

    const pentagramm = latestCalculationResult?.pentagramm;
    const [canvas, setCanvas] = useState<SVGSVGElement>(document.createElementNS("http://www.w3.org/2000/svg", "svg"));

    useEffect(() => {
        if (!pentagramm) return;
        if (!pentagramm.canvas) return;

        const svg = new DOMParser()
            .parseFromString(pentagramm.canvas, "text/html")
            .querySelector("svg");

        if (!svg) return;

        svg.setAttribute("viewBox", "-50 50 900 900");
        setCanvas(svg);
    }, [pentagramm]);

    return <Container css={{maxWidth: "1400px"}}>
        <Spacer />
        <Spacer />

        {/* Heading */}
        <Text
            h1
            size={48}
            css={{ textGradient: "45deg, $blue600 -20%, $pink600 50%", marginBottom: 0}}
            weight="bold"
        >
            Pentagramm
        </Text>

        <Spacer />
        {pentagramm ? (
        <Card>
            <Card.Body>
                <div
                    className="energiebild"
                    dangerouslySetInnerHTML={{ __html: canvas.outerHTML }}
                />
            </Card.Body>
        </Card>
        ) : (
          <NoDataContainer width="50%" height="auto" />
        )}
    </Container>;
}