import React, { useState } from 'react';
import { Grid, Input, Button } from '@nextui-org/react';
import { apiConfig } from "@api/configuration";
import { AuthControllerApi, RegisterUserRequest, UserControllerApi } from "../../../client";
import { DEFAULT_TOASTER_OPTIONS } from "@utils/constants";
import { toast } from "react-toastify"; // Passe dies entsprechend deiner Verzeichnisstruktur an

export const AdminDeleteUserForm = () => {
  const [userId, setUserId] = useState("");
  const api = new UserControllerApi(apiConfig);

  const handleDelete = () => {
    const userIdNum = parseInt(userId);

    if (isNaN(userIdNum)) {
      toast.error("Ungültige Benutzer-ID. Bitte geben Sie eine Zahl ein.", DEFAULT_TOASTER_OPTIONS);
      return;
    }

    api.deleteUser({ id: userIdNum }).then((res) => {
      toast.success("Benutzer wurde erfolgreich gelöscht", DEFAULT_TOASTER_OPTIONS);
    }).catch((error) => {
      toast.error("Fehler beim Löschen des Benutzers", DEFAULT_TOASTER_OPTIONS);
    });

    setUserId("");
  };

  return (
    <Grid.Container gap={2}>
      <Grid>
        <Input
          type="number"
          placeholder="Benutzer ID"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
        />
      </Grid>
      <Grid>
        <Button onClick={handleDelete} auto color="error">
          Löschen
        </Button>
      </Grid>
    </Grid.Container>
  );
};
